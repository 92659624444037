export const labels = {
  Acasă: {
    en: "Home",
    fr: "Accueil",
    it: "Home",
    pt: "Início",
    el: "Αρχική",
    nl: "Startpagina",
    de: "Startseite",
    es: "Inicio",
  },
  Discuție: {
    en: "Chat",
    fr: "Discussion",
    it: "Chat",
    pt: "Conversa",
    el: "Συζήτηση",
    nl: "Chat",
    de: "Chat",
    es: "Chat",
  },
  "Subiecte de forum": {
    en: "Forum Topics",
    fr: "Sujets de forum",
    it: "Argomenti del forum",
    pt: "Tópicos do fórum",
    el: "Θέματα φόρουμ",
    nl: "Forumonderwerpen",
    de: "Forenthemen",
    es: "Temas del foro",
  },
  "Topic forum": {
    en: "Forum Topic",
    fr: "Sujet du forum",
    it: "Argomento del forum",
    pt: "Tópico do fórum",
    el: "Θέμα φόρουμ",
    nl: "Forumonderwerp",
    de: "Forenthema",
    es: "Tema del foro",
  },
  "Răspunsul meu": {
    en: "My Reply",
    fr: "Ma réponse",
    it: "La mia risposta",
    pt: "Minha resposta",
    el: "Η απάντησή μου",
    nl: "Mijn antwoord",
    de: "Meine Antwort",
    es: "Mi respuesta",
  },
  "postat de": {
    en: "posted by",
    fr: "posté par",
    it: "postato da",
    pt: "postado por",
    el: "δημοσιεύτηκε από",
    nl: "geplaatst door",
    de: "veröffentlicht von",
    es: "publicado por",
  },
  "Inel dispoziție": {
    en: "Mood Ring",
    fr: "Bague d'humeur",
    it: "Anello dell'umore",
    pt: "Anel de humor",
    el: "Δαχτυλίδι διάθεσης",
    nl: "Stemmingsring",
    de: "Stimmungsring",
    es: "Anillo del humor",
  },
  Observație: {
    en: "Feedback",
    fr: "Commentaires",
    it: "Feedback",
    pt: "Comentários",
    el: "Σχόλια",
    nl: "Feedback",
    de: "Feedback",
    es: "Comentarios",
  },
  Setări: {
    en: "Settings",
    fr: "Paramètres",
    it: "Impostazioni",
    pt: "Configurações",
    el: "Ρυθμίσεις",
    nl: "Instellingen",
    de: "Einstellungen",
    es: "Configuraciones",
  },
  Despre: {
    en: "About",
    fr: "À propos",
    it: "Informazioni",
    pt: "Sobre",
    el: "Σχετικά",
    nl: "Over",
    de: "Über",
    es: "Acerca de",
  },
  Mentorare: {
    en: "Mentoring",
    fr: "Mentorat",
    it: "Mentoring",
    pt: "Mentoria",
    el: "Καθοδήγηση",
    nl: "Mentorschap",
    de: "Mentoring",
    es: "Mentoría",
  },
  Sesiuni: {
    en: "Sessions",
    fr: "Sessions",
    it: "Sessioni",
    pt: "Sessões",
    el: "Συνεδρίες",
    nl: "Sessies",
    de: "Sitzungen",
    es: "Sesiones",
  },
  Resurse: {
    en: "Resources",
    fr: "Ressources",
    it: "Risorse",
    pt: "Recursos",
    el: "Πόροι",
    nl: "Middelen",
    de: "Ressourcen",
    es: "Recursos",
  },
  Calendar: {
    en: "Calendar",
    fr: "Calendrier",
    it: "Calendario",
    pt: "Calendário",
    el: "Ημερολόγιο",
    nl: "Kalender",
    de: "Kalender",
    es: "Calendario",
  },
  Forum: {
    en: "Forum",
    fr: "Forum",
    it: "Forum",
    pt: "Fórum",
    el: "Φόρουμ",
    nl: "Forum",
    de: "Forum",
    es: "Foro",
  },
  "Ghid utilizare aplicație": {
    en: "App User Guidelines",
    fr: "Guide de l'utilisateur de l'application",
    it: "Linee guida per l'utente dell'app",
    pt: "Diretrizes do usuário do aplicativo",
    el: "Οδηγίες χρήσης της εφαρμογής",
    nl: "App-gebruikersrichtlijnen",
    de: "App-Nutzungsrichtlinien",
    es: "Directrices de uso de la aplicación",
  },
  "EU Funding": {
    en: "EU Funding",
    fr: "Financement de l'UE",
    it: "Finanziamento dell'UE",
    pt: "Financiamento da UE",
    el: "Χρηματοδότηση της ΕΕ",
    nl: "EU-financiering",
    de: "EU-Förderung",
    es: "Financiación de la UE",
  },
  "Ce înseamnă mentorarea?": {
    en: "What is Mentoring?",
    fr: "Qu'est-ce que le mentorat?",
    it: "Cos'è il Mentoring?",
    pt: "O que é Mentoria?",
    el: "Τι είναι η καθοδήγηση;",
    nl: "Wat is mentorschap?",
    de: "Was ist Mentoring?",
    es: "¿Qué es la mentoría?",
  },
  "Politica de Protecție a Copilului": {
    en: "Our Child Protection Policy",
    fr: "Notre politique de protection de l'enfance",
    it: "La nostra politica di protezione dei bambini",
    pt: "Nossa Política de Proteção à Criança",
    el: "Η Πολιτική Προστασίας Παιδιών μας",
    nl: "Ons kinderbeschermingsbeleid",
    de: "Unsere Kinderschutzrichtlinie",
    es: "Nuestra política de protección infantil",
  },
  Parteneriat: {
    en: "Partnership",
    fr: "Partenariat",
    it: "Partenariato",
    pt: "Parceria",
    el: "Συνεργασία",
    nl: "Partnerschap",
    de: "Partnerschaft",
    es: "Asociación",
  },
  "Finanțare UE": {
    en: "EU Funding",
    fr: "Financement de l'UE",
    it: "Finanziamento dell'UE",
    pt: "Financiamento da UE",
    el: "Χρηματοδότηση της ΕΕ",
    nl: "EU-financiering",
    de: "EU-Förderung",
    es: "Financiación de la UE",
  },
  "Contactează-ne": {
    en: "Contact Us",
    fr: "Contactez-nous",
    it: "Contattaci",
    pt: "Contate-Nos",
    el: "Επικοινωνήστε μαζί μας",
    nl: "Neem contact op",
    de: "Kontaktieren Sie uns",
    es: "Contáctanos",
  },
  "Setări cont": {
    en: "Account Settings",
    fr: "Paramètres du compte",
    it: "Impostazioni account",
    pt: "Configurações da conta",
    el: "Ρυθμίσεις λογαριασμού",
    nl: "Accountinstellingen",
    de: "Kontoeinstellungen",
    es: "Configuración de la cuenta",
  },
  "Schimbă parola": {
    en: "Change Password",
    fr: "Changer le mot de passe",
    it: "Cambia la password",
    pt: "Mudar senha",
    el: "Αλλαγή κωδικού πρόσβασης",
    nl: "Wachtwoord wijzigen",
    de: "Passwort ändern",
    es: "Cambiar contraseña",
  },
  Salvează: {
    en: "Save",
    fr: "Enregistrer",
    it: "Salva",
    pt: "Salvar",
    el: "Αποθήκευση",
    nl: "Opslaan",
    de: "Speichern",
    es: "Guardar",
  },
  "Atașează fișiere": {
    en: "Attach Files",
    fr: "Joindre des fichiers",
    it: "Allega file",
    pt: "Anexar arquivos",
    el: "Επισύναψη αρχείων",
    nl: "Bestanden toevoegen",
    de: "Dateien anhängen",
    es: "Adjuntar archivos",
  },
  "Ceva nu a mers bine": {
    en: "Something went wrong",
    fr: "Quelque chose s'est mal passé",
    it: "Qualcosa è andato storto",
    pt: "Algo deu errado",
    el: "Κάτι πήγε στραβά",
    nl: "Er is iets misgegaan",
    de: "Etwas ist schiefgelaufen",
    es: "Algo salió mal",
  },
  "întâlnire online": {
    en: "online meeting",
    fr: "réunion en ligne",
    it: "incontro online",
    pt: "reunião online",
    el: "διαδικτυακή συνάντηση",
    nl: "online vergadering",
    de: "Online-Meeting",
    es: "reunión en línea",
  },
  "Link-ul sesiunii online": {
    en: "Online meeting link",
    fr: "Lien de réunion en ligne",
    it: "Link alla riunione online",
    pt: "Link da reunião online",
    el: "Σύνδεσμος διαδικτυακής συνάντησης",
    nl: "Link naar online vergadering",
    de: "Link zum Online-Meeting",
    es: "Enlace a la reunión en línea",
  },
  "întâlnire față în față": {
    en: "face-to-face meeting",
    fr: "réunion en face à face",
    it: "incontro faccia a faccia",
    pt: "reunião presencial",
    el: "συνάντηση πρόσωπο με πρόσωπο",
    nl: "persoonlijke vergadering",
    de: "persönliches Treffen",
    es: "reunión cara a cara",
  },
  "Popune selecția ca dată de întâlnire": {
    en: "Propose Selection for Meeting",
    fr: "Proposer la sélection pour la réunion",
    it: "Proporre selezione per la riunione",
    pt: "Propor seleção para reunião",
    el: "Προτείνετε επιλογή για συνάντηση",
    nl: "Selectie voorstellen voor vergadering",
    de: "Auswahl für das Treffen vorschlagen",
    es: "Proponer selección para la reunión",
  },
  "Adresa fizică": {
    en: "Physical address",
    fr: "Adresse physique",
    it: "Indirizzo fisico",
    pt: "Endereço físico",
    el: "Φυσική διεύθυνση",
    nl: "Fysiek adres",
    de: "Physische Adresse",
    es: "Dirección física",
  },
  "Acceptă data propusă": {
    en: "Accept the proposed date",
    fr: "Accepter la date proposée",
    it: "Accetta la data proposta",
    pt: "Aceitar a data proposta",
    el: "Αποδοχή της προτεινόμενης ημερομηνίας",
    nl: "Accepteer de voorgestelde datum",
    de: "Akzeptieren Sie das vorgeschlagene Datum",
    es: "Aceptar la fecha propuesta",
  },
  "Aștept confirmare de la": {
    en: "Awaiting confirmation from",
    fr: "En attente de confirmation de",
    it: "In attesa di conferma da",
    pt: "Aguardando confirmação de",
    el: "Αναμονή επιβεβαίωσης από",
    nl: "In afwachting van bevestiging van",
    de: "Warte auf Bestätigung von",
    es: "Esperando confirmación de",
  },
  "întâlnire agreată": {
    en: "meeting agreed",
    fr: "réunion acceptée",
    it: "incontro concordato",
    pt: "reunião acordada",
    el: "συνάντηση συμφωνημένη",
    nl: "overeengekomen vergadering",
    de: "vereinbarte Besprechung",
    es: "reunión acordada",
  },
  "Solicit modificare": {
    en: "Request Change",
    fr: "Demander une modification",
    it: "Richiedi modifica",
    pt: "Solicitar alteração",
    el: "Αίτημα αλλαγής",
    nl: "Verzoek om wijziging",
    de: "Änderung anfordern",
    es: "Solicitar cambio",
  },
  Anulează: {
    en: "Cancel",
    fr: "Annuler",
    it: "Annulla",
    pt: "Cancelar",
    el: "Ακύρωση",
    nl: "Annuleren",
    de: "Abbrechen",
    es: "Cancelar",
  },
  "Gestiune echipe mentorare": {
    en: "Mentoring Teams Management",
    fr: "Gestion des équipes de mentorat",
    it: "Gestione dei team di mentoring",
    pt: "Gestão de equipes de mentoria",
    el: "Διαχείριση ομάδων καθοδήγησης",
    nl: "Beheer van mentorteams",
    de: "Mentoring-Teams-Management",
    es: "Gestión de equipos de mentoría",
  },
  "Mentorare în curs": {
    en: "Ongoing Mentoring",
    fr: "Mentorat en cours",
    it: "Mentoring in corso",
    pt: "Mentoria em andamento",
    el: "Σε εξέλιξη καθοδήγηση",
    nl: "Lopend mentorschap",
    de: "Laufendes Mentoring",
    es: "Mentoría en curso",
  },
  "Persoane nementorate": {
    en: "Unmentored persons",
    fr: "Personnes non mentorées",
    it: "Persone non mentore",
    pt: "Pessoas não mentoradas",
    el: "Μη καθοδηγούμενα άτομα",
    nl: "Niet-mentored personen",
    de: "Nicht mentorierte Personen",
    es: "Personas no mentoradas",
  },
  "Sesiuni de mentorare": {
    en: "Mentoring Sessions",
    fr: "Sessions de mentorat",
    it: "Sessioni di mentoring",
    pt: "Sessões de mentoria",
    el: "Συνεδρίες καθοδήγησης",
    nl: "Mentorschapsessies",
    de: "Mentorensitzungen",
    es: "Sesiones de mentoría",
  },
  "Ultima ta întâlnire a fost pe": {
    en: "Your last meeting was on",
    fr: "Votre dernière réunion a eu lieu le",
    it: "Il tuo ultimo incontro è stato il",
    pt: "Sua última reunião foi em",
    el: "Η τελευταία σας συνάντηση ήταν στις",
    nl: "Je laatste vergadering was op",
    de: "Ihr letztes Treffen war am",
    es: "Tu última reunión fue el",
  },
  "Următoarea sesiune va fi pe": {
    en: "Next meeting will be on",
    fr: "La prochaine réunion aura lieu le",
    it: "Il prossimo incontro sarà il",
    pt: "A próxima reunião será em",
    el: "Η επόμενη συνάντηση θα είναι στις",
    nl: "De volgende vergadering is op",
    de: "Das nächste Treffen wird am",
    es: "La próxima reunión será el",
  },
  "Data și locul": {
    en: "Date and location",
    fr: "Date et lieu",
    it: "Data e luogo",
    pt: "Data e local",
    el: "Ημερομηνία και τοποθεσία",
    nl: "Datum en locatie",
    de: "Datum und Ort",
    es: "Fecha y lugar",
  },
  "Acord de mentorat": {
    en: "Mentoring Agreement",
    fr: "Accord de mentorat",
    it: "Accordo di mentoring",
    pt: "Acordo de mentoria",
    el: "Συμφωνία καθοδήγησης",
    nl: "Mentorschapsafspraak",
    de: "Mentoring-Vereinbarung",
    es: "Acuerdo de mentoría",
  },
  "Editare relație de mentorat": {
    en: "Edit Mentoring Relationship",
    fr: "Modifier la relation de mentorat",
    it: "Modifica relazione di mentoring",
    pt: "Editar relacionamento de mentoria",
    el: "Επεξεργασία σχέσης καθοδήγησης",
    nl: "Mentorschapsrelatie bewerken",
    de: "Mentoring-Beziehung bearbeiten",
    es: "Editar relación de mentoría",
  },
  "Adăugare relație de mentorat": {
    en: "Add Mentoring Relationship",
    fr: "Ajouter une relation de mentorat",
    it: "Aggiungi relazione di mentoring",
    pt: "Adicionar relacionamento de mentoria",
    el: "Προσθήκη σχέσης καθοδήγησης",
    nl: "Mentorschapsrelatie toevoegen",
    de: "Mentoring-Beziehung hinzufügen",
    es: "Agregar relación de mentoría",
  },
  "Șterge relația de mentorare": {
    en: "Delete Mentoring Relationship",
    fr: "Supprimer la relation de mentorat",
    it: "Elimina relazione di mentoring",
    pt: "Excluir relacionamento de mentoria",
    el: "Διαγραφή σχέσης καθοδήγησης",
    nl: "Mentorschapsrelatie verwijderen",
    de: "Mentoring-Beziehung löschen",
    es: "Eliminar relación de mentoría",
  },
  "Doriți să ștergeți relația.": {
    en: "Do you want to delete the relationship?",
    fr: "Voulez-vous supprimer la relation?",
    it: "Vuoi eliminare la relazione?",
    pt: "Deseja excluir o relacionamento?",
    el: "Θέλετε να διαγράψετε τη σχέση;",
    nl: "Wil je de relatie verwijderen?",
    de: "Möchten Sie die Beziehung löschen?",
    es: "¿Desea eliminar la relación?",
  },
  "Număr de ședințe": {
    en: "Number of Sessions",
    fr: "Nombre de sessions",
    it: "Numero di sessioni",
    pt: "Número de sessões",
    el: "Αριθμός συνεδριών",
    nl: "Aantal sessies",
    de: "Anzahl der Sitzungen",
    es: "Número de sesiones",
  },
  "Data începerii": {
    en: "Start Date",
    fr: "Date de début",
    it: "Data di inizio",
    pt: "Data de início",
    el: "Ημερομηνία έναρξης",
    nl: "Startdatum",
    de: "Startdatum",
    es: "Fecha de inicio",
  },
  "Data finalizării": {
    en: "End Date",
    fr: "Date de fin",
    it: "Data di fine",
    pt: "Data de término",
    el: "Ημερομηνία λήξης",
    nl: "Einddatum",
    de: "Enddatum",
    es: "Fecha de finalización",
  },
  "Vizualizare observație": {
    en: "View Feedback",
    fr: "Voir les commentaires",
    it: "Visualizza feedback",
    pt: "Ver Feedback",
    el: "Προβολή σχολίων",
    nl: "Feedback bekijken",
    de: "Feedback anzeigen",
    es: "Ver comentarios",
  },
  "Trimite observație": {
    en: "Provide Feedback",
    fr: "Fournir des commentaires",
    it: "Fornire feedback",
    pt: "Fornecer Feedback",
    el: "Παροχή σχολίων",
    nl: "Feedback geven",
    de: "Feedback geben",
    es: "Proporcionar comentarios",
  },
  Trimite: {
    en: "Send",
    fr: "Envoyer",
    it: "Invia",
    pt: "Enviar",
    el: "Αποστολή",
    nl: "Verzenden",
    de: "Senden",
    es: "Enviar",
  },
  "Validare coordonatori": {
    en: "Coordinator Approval",
    fr: "Approbation du coordinateur",
    it: "Approvazione del coordinatore",
    pt: "Aprovação do coordenador",
    el: "Έγκριση συντονιστή",
    nl: "Goedkeuring coördinator",
    de: "Genehmigung des Koordinators",
    es: "Aprobación del coordinador",
  },
  "Pagina anterioară": {
    en: "Previous Page",
    fr: "Page précédente",
    it: "Pagina precedente",
    pt: "Página anterior",
    el: "Προηγούμενη σελίδα",
    nl: "Vorige pagina",
    de: "Vorherige Seite",
    es: "Página anterior",
  },
  "Printeaza": {
    en: "Print",
    fr: "Imprimer",
    it: "Stampa",
    pt: "Imprimir",
    el: "Εκτύπωση",
    nl: "Afdrukken",
    de: "Drucken",
    es: "Imprimir",
  },
  "Mentoring pentru a implica, a conecta și <br />a face puternici tinerii, <span class='text-primary'> VIITORUL NOSTRU</span>!": {
    en: "Mentoring to engage, connect and <br />empower young people, <span class='text-primary'> OUR FUTURE</span>!",
    fr: "Mentorat pour engager, connecter et <br />responsabiliser les jeunes, <span class='text-primary'> NOTRE AVENIR</span>!",
    it: "Mentoring per coinvolgere, connettere e <br />responsabilizzare i giovani, <span class='text-primary'> IL NOSTRO FUTURO</span>!",
    pt: "Mentoria para engajar, conectar e <br />capacitar os jovens, <span class='text-primary'> NOSSO FUTURO</span>!",
    el: "Καθοδήγηση για να εμπλέξει, να συνδέσει και <br />να ενδυναμώσει τους νέους, <span class='text-primary'> ΤΟ ΜΕΛΛΟΝ ΜΑΣ</span>!",
    nl: "Mentorschap om jongeren te betrekken, verbinden en <br />te versterken, <span class='text-primary'> ONZE TOEKOMST</span>!",
    de: "Mentoring, um junge Menschen zu beteiligen, zu verbinden und <br />zu stärken, <span class='text-primary'> UNSERE ZUKUNFT</span>!",
    es: "Mentoría para involucrar, conectar y <br />empoderar a los jóvenes, <span class='text-primary'> NUESTRO FUTURO</span>!",
  },
  "— Selectează —": {
    en: "— Select —",
    fr: "— Sélectionner —",
    it: "— Seleziona —",
    pt: "— Selecionar —",
    el: "— Επιλέξτε —",
    nl: "— Selecteren —",
    de: "— Auswählen —",
    es: "— Seleccionar —",
  },
  "— Selecție sesiune (opțional) —": {
    en: "— Select Session (optional) —",
    fr: "— Sélectionner une session (optionnel) —",
    it: "— Seleziona sessione (opzionale) —",
    pt: "— Selecionar sessão (opcional) —",
    el: "— Επιλέξτε συνεδρία (προαιρετικό) —",
    nl: "— Selecteer sessie (optioneel) —",
    de: "— Sitzung auswählen (optional) —",
    es: "— Seleccionar sesión (opcional) —",
  },
  "— Selecție locație —": {
    en: "— Select Location —",
    fr: "— Sélectionner un lieu —",
    it: "— Seleziona luogo —",
    pt: "— Selecionar local —",
    el: "— Επιλέξτε τοποθεσία —",
    nl: "— Selecteer locatie —",
    de: "— Standort auswählen —",
    es: "— Seleccionar ubicación —",
  },
  "— Selecție rol —": {
    en: "— Select Your Role —",
    fr: "— Sélectionner votre rôle —",
    it: "— Seleziona il tuo ruolo —",
    pt: "— Selecionar seu papel —",
    el: "— Επιλέξτε το ρόλο σας —",
    nl: "— Selecteer uw rol —",
    de: "— Wählen Sie Ihre Rolle —",
    es: "— Selecciona tu rol —",
  },
  "— Selecție țară —": {
    en: "— Select Your Country —",
    fr: "— Sélectionner votre pays —",
    it: "— Seleziona il tuo paese —",
    pt: "— Selecionar seu país —",
    el: "— Επιλέξτε τη χώρα σας —",
    nl: "— Selecteer uw land —",
    de: "— Wählen Sie Ihr Land —",
    es: "— Selecciona tu país —",
  },
  "- Selecție organizație (opțional) -": {
    en: "— Select Organization (optional) —",
    fr: "— Sélectionner une organisation (optionnel) —",
    it: "— Seleziona organizzazione (opzionale) —",
    pt: "— Selecionar organização (opcional) —",
    el: "— Επιλέξτε οργανισμό (προαιρετικό) —",
    nl: "— Selecteer organisatie (optioneel) —",
    de: "— Organisation auswählen (optional) —",
    es: "— Seleccionar organización (opcional) —",
  },
  Admin: {
    en: "Admin",
    fr: "Admin",
    it: "Admin",
    pt: "Admin",
    el: "Admin",
    nl: "Admin",
    de: "Admin",
    es: "Admin",
  },
  Coordonator: {
    en: "Coordinator",
    fr: "Coordinateur",
    it: "Coordinatore",
    pt: "Coordenador",
    el: "Συντονιστής",
    nl: "Coördinator",
    de: "Koordinator",
    es: "Coordinador",
  },
  Mentor: {
    en: "Mentor",
    fr: "Mentor",
    it: "Mentore",
    pt: "Mentor",
    el: "Μέντορας",
    nl: "Mentor",
    de: "Mentor",
    es: "Mentor",
  },
  Mentorat: {
    en: "Mentee",
    fr: "Mentoré",
    it: "Mentorato",
    pt: "Mentorado",
    el: "Καθοδηγούμενος",
    nl: "Mentorade",
    de: "Mentee",
    es: "Mentorado",
  },
  "Planificare sesiune": {
    en: "Session Planning",
    fr: "Planification de session",
    it: "Pianificazione della sessione",
    pt: "Planejamento de sessão",
    el: "Προγραμματισμός συνεδρίας",
    nl: "Sessieplanning",
    de: "Sitzungsplanung",
    es: "Planificación de sesión",
  },
  "Utilizator sau Email": {
    en: "Username or Email",
    fr: "Nom d'utilisateur ou e-mail",
    it: "Nome utente o email",
    pt: "Nome de usuário ou email",
    el: "Όνομα χρήστη ή email",
    nl: "Gebruikersnaam of email",
    de: "Benutzername oder E-Mail",
    es: "Nombre de usuario o correo electrónico",
  },
  Parolă: {
    en: "Password",
    fr: "Mot de passe",
    it: "Password",
    pt: "Senha",
    el: "Κωδικός πρόσβασης",
    nl: "Wachtwoord",
    de: "Passwort",
    es: "Contraseña",
  },
  "Confirmare Parolă": {
    en: "Confirm Password",
    fr: "Confirmer le mot de passe",
    it: "Conferma Password",
    pt: "Confirmar Senha",
    el: "Επιβεβαίωση Κωδικού",
    nl: "Bevestig Wachtwoord",
    de: "Passwort bestätigen",
    es: "Confirmar contraseña",
  },
  Nume: {
    en: "First Name",
    fr: "Prénom",
    it: "Nome",
    pt: "Nome",
    el: "Όνομα",
    nl: "Voornaam",
    de: "Vorname",
    es: "Nombre",
  },
  Prenume: {
    en: "Last Name",
    fr: "Nom",
    it: "Cognome",
    pt: "Sobrenome",
    el: "Επώνυμο",
    nl: "Achternaam",
    de: "Nachname",
    es: "Apellido",
  },
  Email: {
    en: "Email",
    fr: "Email",
    it: "Email",
    pt: "Email",
    el: "Email",
    nl: "Email",
    de: "E-Mail",
    es: "Correo electrónico",
  },
  "Înregistrează-te": {
    en: "Sign Up",
    fr: "S'inscrire",
    it: "Iscriviti",
    pt: "Inscrever-se",
    el: "Εγγραφείτε",
    nl: "Aanmelden",
    de: "Anmelden",
    es: "Registrarse",
  },
  "Intră în cont": {
    en: "Login",
    fr: "Connexion",
    it: "Accedi",
    pt: "Entrar",
    el: "Σύνδεση",
    nl: "Inloggen",
    de: "Einloggen",
    es: "Iniciar sesión",
  },
  "Link activare cont": {
    en: "Account activation link",
    fr: "Lien d'activation de compte",
    it: "Link di attivazione dell'account",
    pt: "Link de ativação da conta",
    el: "Σύνδεσμος ενεργοποίησης λογαριασμού",
    nl: "Account activatielink",
    de: "Link zur Kontobestätigung",
    es: "Enlace de activación de cuenta",
  },
  "Recuperare parolă": {
    en: "Password Recovery",
    fr: "Récupération de mot de passe",
    it: "Recupero Password",
    pt: "Recuperação de senha",
    el: "Ανάκτηση κωδικού πρόσβασης",
    nl: "Wachtwoordherstel",
    de: "Passwort-Wiederherstellung",
    es: "Recuperación de contraseña",
  },
  "Adresa de email": {
    en: "Email Address",
    fr: "Adresse e-mail",
    it: "Indirizzo email",
    pt: "Endereço de email",
    el: "Διεύθυνση email",
    nl: "E-mailadres",
    de: "E-Mail-Adresse",
    es: "Dirección de correo electrónico",
  },
  "Vei primi un email cu link pentru resetare parolă": {
    en: "You will receive an email with a password reset link",
    fr: "Vous recevrez un e-mail avec un lien de réinitialisation du mot de passe",
    it: "Riceverai un'email con il link per reimpostare la password",
    pt: "Você receberá um email com um link para redefinir a senha",
    el: "Θα λάβετε ένα email με έναν σύνδεσμο επαναφοράς κωδικού πρόσβασης",
    nl: "Je ontvangt een e-mail met een link om je wachtwoord te resetten",
    de: "Sie erhalten eine E-Mail mit einem Link zum Zurücksetzen des Passworts",
    es: "Recibirás un correo electrónico con un enlace para restablecer la contraseña",
  },
  "Trimite email": {
    en: "Send email",
    fr: "Envoyer un e-mail",
    it: "Invia email",
    pt: "Enviar email",
    el: "Αποστολή email",
    nl: "Email verzenden",
    de: "E-Mail senden",
    es: "Enviar correo electrónico",
  },
  "Un email a fost trimis la @email. Verifică dacă ai primit email-ul în Inbox sau în folderul Spam (email nedorit)": {
    en: "An email has been sent to @email. Check if you received the email in the Inbox or in the Spam folder.",
    fr: "Un e-mail a été envoyé à @email. Vérifiez si vous avez reçu l'e-mail dans la boîte de réception ou dans le dossier spam.",
    it: "Un'email è stata inviata a @email. Controlla se hai ricevuto l'email nella casella di posta in arrivo o nella cartella spam.",
    pt: "Um email foi enviado para @email. Verifique se você recebeu o email na Caixa de Entrada ou na pasta de Spam.",
    el: "Ένα email στάλθηκε στο @email. Ελέγξτε αν λάβατε το email στα Εισερχόμενα ή στο φάκελο Spam.",
    nl: "Er is een e-mail verzonden naar @email. Controleer of je de e-mail in de Inbox of de Spam-map hebt ontvangen.",
    de: "Eine E-Mail wurde an @email gesendet. Überprüfen Sie, ob Sie die E-Mail im Posteingang oder im Spam-Ordner erhalten haben.",
    es: "Se ha enviado un correo a @email. Comprueba si has recibido el correo en la Bandeja de entrada o en la carpeta de Spam.",
  },
  "Înapoi la conectare": {
    en: "Back to login",
    fr: "Retour à la connexion",
    it: "Torna al login",
    pt: "Voltar ao login",
    el: "Επιστροφή στη σύνδεση",
    nl: "Terug naar inloggen",
    de: "Zurück zum Login",
    es: "Volver al inicio de sesión",
  },
  "<b>Crează o parolă</b> și <b>repetă parola</b> trebuie să fie identice": {
    en: "<b>Create a password</b> and <b>repeat the password</b> must be identical",
    fr: "<b>Créez un mot de passe</b> et <b>répétez le mot de passe</b> doivent être identiques",
    it: "<b>Crea una password</b> e <b>ripeti la password</b> devono essere identiche",
    pt: "<b>Crie uma senha</b> e <b>repita a senha</b> devem ser idênticas",
    el: "<b>Δημιουργήστε έναν κωδικό πρόσβασης</b> και <b>επαναλάβετε τον κωδικό πρόσβασης</b> πρέπει να είναι ίδιοι",
    nl: "<b>Maak een wachtwoord</b> en <b>herhaal het wachtwoord</b> moeten identiek zijn",
    de: "<b>Erstellen Sie ein Passwort</b> und <b>wiederholen Sie das Passwort</b> müssen identisch sein",
    es: "<b>Crea una contraseña</b> y <b>repite la contraseña</b> deben ser idénticas",
  },
  "Resetare parolă": {
    en: "Password Reset",
    fr: "Réinitialisation du mot de passe",
    it: "Reimpostazione della password",
    pt: "Redefinição de senha",
    el: "Επαναφορά κωδικού πρόσβασης",
    nl: "Wachtwoord resetten",
    de: "Passwort zurücksetzen",
    es: "Restablecimiento de contraseña",
  },
  "Setează noua parolă": {
    en: "Set new password",
    fr: "Définir un nouveau mot de passe",
    it: "Imposta una nuova password",
    pt: "Definir nova senha",
    el: "Ορίστε νέο κωδικό πρόσβασης",
    nl: "Nieuw wachtwoord instellen",
    de: "Neues Passwort festlegen",
    es: "Establecer nueva contraseña",
  },
  "Cont creat cu succes !": {
    en: "Account created successfully!",
    fr: "Compte créé avec succès!",
    it: "Account creato con successo!",
    pt: "Conta criada com sucesso!",
    el: "Ο λογαριασμός δημιουργήθηκε με επιτυχία!",
    nl: "Account succesvol aangemaakt!",
    de: "Konto erfolgreich erstellt!",
    es: "¡Cuenta creada con éxito!",
  },
  "Vei putea să te conectezi după ce îți validezi adresa de email ! <br /> Verifică căsuța de email <b>@email</b> și folosește link-ul de activare primit. Verifică atât în folderul <b>inbox</b> cât și în folderul <b>spam</b>.":
  {
    en: "You will be able to log in after validating your email address! <br /> Check your email box <b>@email</b> and use the activation link received. Check both the <b>inbox</b> and <b>spam</b> folders.",
    fr: "Vous pourrez vous connecter après avoir validé votre adresse e-mail! <br /> Vérifiez votre boîte e-mail <b>@email</b> et utilisez le lien d'activation reçu. Vérifiez à la fois les dossiers <b>boîte de réception</b> et <b>spam</b>.",
    it: "Potrai accedere dopo aver convalidato il tuo indirizzo email! <br /> Controlla la tua casella di posta elettronica <b>@email</b> e utilizza il link di attivazione ricevuto. Controlla sia la cartella <b>posta in arrivo</b> che la cartella <b>spam</b>.",
    pt: "Você poderá fazer login após validar seu endereço de e-mail! <br /> Verifique sua caixa de e-mail <b>@email</b> e use o link de ativação recebido. Verifique as pastas <b>Caixa de Entrada</b> e <b>Spam</b>.",
    el: "Θα μπορείτε να συνδεθείτε αφού επικυρώσετε τη διεύθυνση email σας! <br /> Ελέγξτε το γραμματοκιβώτιο σας <b>@email</b> και χρησιμοποιήστε τον σύνδεσμο ενεργοποίησης που λάβατε. Ελέγξτε τόσο τους φακέλους <b>εισερχόμενα</b> όσο και τους φακέλους <b>ανεπιθύμητα</b>.",
    nl: "U kunt inloggen nadat u uw e-mailadres heeft gevalideerd! <br /> Controleer uw e-mailbox <b>@email</b> en gebruik de ontvangen activeringslink. Controleer zowel de <b>inbox</b> als de <b>spam</b> mappen.",
    de: "Sie können sich anmelden, nachdem Sie Ihre E-Mail-Adresse bestätigt haben! <br /> Überprüfen Sie Ihr Postfach <b>@email</b> und verwenden Sie den empfangenen Aktivierungslink. Überprüfen Sie sowohl den <b>Posteingang</b> als auch den <b>Spam</b>-Ordner.",
    es: "Podrás iniciar sesión después de validar tu dirección de correo electrónico. <br /> Revisa tu bandeja de entrada <b>@email</b> y usa el enlace de activación recibido. Revisa tanto en la bandeja de <b>entrada</b> como en la carpeta de <b>spam</b>.",
  },
  "Te-ai înregistrat cu succes!": {
    en: "You have successfully registered!",
    fr: "Vous vous êtes inscrit avec succès!",
    it: "Ti sei registrato con successo!",
    pt: "Você se registrou com sucesso!",
    el: "Έχετε εγγραφεί με επιτυχία!",
    nl: "Je hebt je succesvol geregistreerd!",
    de: "Sie haben sich erfolgreich registriert!",
    es: "¡Te has registrado con éxito!"
  },
  'Contul <b>@email</b> este deja înregistrat. Nu mai știi parola ? Folosește opțiunea <a href="/password-recover" class="card-link">resetare parolă</a>.': {
    en: 'The <b>@email</b> account is already registered. Don\'t you remember the password? Use the <a href="/password-recover" class="card-link">password reset</a> option.',
    fr: 'Le compte <b>@email</b> est déjà enregistré. Vous ne vous souvenez plus du mot de passe? Utilisez l\'option <a href="/password-recover" class="card-link">réinitialisation du mot de passe</a>.',
    it: 'L\'account <b>@email</b> è già registrato. Non ricordi la password? Utilizza l\'opzione di <a href="/password-recover" class="card-link">reimpostazione della password</a>.',
    pt: 'A conta <b>@email</b> já está registrada. Você não se lembra da senha? Use a opção de <a href="/password-recover" class="card-link">redefinição de senha</a>.',
    el: 'Ο λογαριασμός <b>@email</b> είναι ήδη καταχωρημένος. Δεν θυμάστε τον κωδικό πρόσβασης; Χρησιμοποιήστε την επιλογή <a href="/password-recover" class="card-link">επαναφοράς κωδικού πρόσβασης</a>.',
    nl: 'Het account <b>@email</b> is al geregistreerd. Weet je het wachtwoord niet meer? Gebruik de optie voor <a href="/password-recover" class="card-link">wachtwoord reset</a>.',
    de: 'Das Konto <b>@email</b> ist bereits registriert. Erinnern Sie sich nicht an das Passwort? Verwenden Sie die Option <a href="/password-recover" class="card-link">Passwort zurücksetzen</a>.',
    es: 'La cuenta <b>@email</b> ya está registrada. ¿No recuerdas la contraseña? Usa la opción de <a href="/password-recover" class="card-link">restablecimiento de contraseña</a>.'
  },
  "Contul nu a fost activat !<br/> Verifică căsuța de email ( <b>inbox și spam </b> ) pentru link-ul de activare.": {
    en: "The account has not been activated! <br/> Check the email box ( <b>inbox and spam </b> ) for the activation link.",
    fr: "Le compte n'a pas été activé! <br/> Vérifiez la boîte de réception ( <b>inbox et spam </b> ) pour le lien d'activation.",
    it: "L'account non è stato attivato! <br/> Controlla la casella di posta elettronica ( <b>posta in arrivo e spam </b> ) per il link di attivazione.",
    pt: "A conta não foi ativada! <br/> Verifique a caixa de e-mail ( <b>Caixa de entrada e Spam </b> ) para o link de ativação.",
    el: "Ο λογαριασμός δεν έχει ενεργοποιηθεί! <br/> Ελέγξτε το γραμματοκιβώτιο ( <b>εισερχόμενα και ανεπιθύμητα </b> ) για τον σύνδεσμο ενεργοποίησης.",
    nl: "Het account is niet geactiveerd! <br/> Controleer de e-mailbox ( <b>inbox en spam </b> ) voor de activeringslink.",
    de: "Das Konto wurde nicht aktiviert! <br/> Überprüfen Sie das E-Mail-Postfach ( <b>Posteingang und Spam </b> ) auf den Aktivierungslink.",
    es: "¡La cuenta no ha sido activada! <br/> Revisa tu bandeja de entrada ( <b>correo no deseado </b> ) para el enlace de activación."
  },
  "Cont <b>temporar dezactivat</b>. Poți încerca din nou peste @timeout minute.": {
    en: "Account <b>temporarily disabled</b>. You can try again in <b>@timeout</b> minutes.",
    fr: "Compte <b>temporairement désactivé</b>. Vous pouvez réessayer dans <b>@timeout</b> minutes.",
    it: "Account <b>temporaneamente disabilitato</b>. Puoi riprovare tra <b>@timeout</b> minuti.",
    pt: "Conta <b>temporariamente desativada</b>. Você pode tentar novamente em <b>@timeout</b> minutos.",
    el: "Ο λογαριασμός είναι <b>προσωρινά απενεργοποιημένος</b>. Μπορείτε να δοκιμάσετε ξανά σε <b>@timeout</b> λεπτά.",
    nl: "Account <b>tijdelijk uitgeschakeld</b>. U kunt het over <b>@timeout</b> minuten opnieuw proberen.",
    de: "Konto <b>vorübergehend deaktiviert</b>. Sie können es in <b>@timeout</b> Minuten erneut versuchen.",
    es: "Cuenta <b>temporalmente desactivada</b>. Puedes intentarlo de nuevo en <b>@timeout</b> minutos."
  },
  'Nu mai știi parola ? Accesează <a href="/password-recover" class="card-link">resetează parola</a>.': {
    en: 'Don\'t you remember the password? Access <a href="/password-recover" class="card-link">reset password</a>.',
    fr: 'Vous ne vous souvenez plus du mot de passe? Accédez à <a href="/password-recover" class="card-link">réinitialisation du mot de passe</a>.',
    it: 'Non ricordi la password? Accedi a <a href="/password-recover" class="card-link">reimposta password</a>.',
    pt: 'Você não se lembra da senha? Acesse <a href="/password-recover" class="card-link">redefinir senha</a>.',
    el: 'Δεν θυμάστε τον κωδικό πρόσβασης; Αποκτήστε πρόσβαση στην <a href="/password-recover" class="card-link">επαναφορά κωδικού πρόσβασης</a>.',
    nl: 'Weet je het wachtwoord niet meer? Ga naar <a href="/password-recover" class="card-link">wachtwoord resetten</a>.',
    de: 'Erinnern Sie sich nicht an das Passwort? Greifen Sie auf <a href="/password-recover" class="card-link">Passwort zurücksetzen</a> zu.',
    es: '¿No recuerdas la contraseña? Accede a <a href="/password-recover" class="card-link">restablecer contraseña</a>.'
  },
  "Nu există cont asociat cu acest email !": {
    en: "There is no account associated with this email!",
    fr: "Il n'y a aucun compte associé à cet e-mail!",
    it: "Non c'è nessun account associato a questa email!",
    pt: "Não há nenhuma conta associada a este email!",
    el: "Δεν υπάρχει λογαριασμός συνδεδεμένος με αυτό το email!",
    nl: "Er is geen account gekoppeld aan dit e-mailadres!",
    de: "Es ist kein Konto mit dieser E-Mail-Adresse verbunden!",
    es: "¡No hay ninguna cuenta asociada a este correo electrónico!"
  },
  'Acest link a fost deja folosit!<hr/> Poți încerca să te <a href="/login"> autentifici </a> cu email și parolă, sau poți folosi opțiunea <a href="/password-recover">resetare parolă</a> dacă nu mai știi parola.</p>': {
    en: 'This link has already been used! <hr/> You can try to <a href="/login"> log in </a> with email and password, or you can use the <a href="/password-recover">reset password</a> option if you don\'t remember the password.</p>',
    fr: 'Ce lien a déjà été utilisé! <hr/> Vous pouvez essayer de <a href="/login"> vous connecter </a> avec e-mail et mot de passe, ou vous pouvez utiliser l\'option de <a href="/password-recover">réinitialisation du mot de passe</a> si vous ne vous souvenez pas du mot de passe.</p>',
    it: 'Questo link è già stato utilizzato! <hr/> Puoi provare a <a href="/login"> accedere </a> con email e password, oppure puoi utilizzare l\'opzione di <a href="/password-recover">reimpostazione della password</a> se non ricordi la password.</p>',
    pt: 'Este link já foi utilizado! <hr/> Você pode tentar <a href="/login"> fazer login </a> com email e senha, ou pode usar a opção de <a href="/password-recover">redefinição de senha</a> se não lembrar a senha.</p>',
    el: 'Αυτός ο σύνδεσμος έχει ήδη χρησιμοποιηθεί! <hr/> Μπορείτε να δοκιμάσετε να <a href="/login"> συνδεθείτε </a> με email και κωδικό πρόσβασης ή μπορείτε να χρησιμοποιήσετε την επιλογή <a href="/password-recover">επαναφοράς κωδικού πρόσβασης</a> εάν δεν θυμάστε τον κωδικό πρόσβασης.</p>',
    nl: 'Deze link is al gebruikt! <hr/> U kunt proberen om in te <a href="/login"> loggen </a> met e-mail en wachtwoord, of u kunt de optie voor <a href="/password-recover">wachtwoord resetten</a> gebruiken als u het wachtwoord niet meer weet.</p>',
    de: 'Dieser Link wurde bereits verwendet! <hr/> Sie können versuchen, sich mit Ihrer E-Mail und Ihrem Passwort <a href="/login"> anzumelden </a>, oder Sie verwenden die <a href="/password-recover">Passwort zurücksetzen</a> Option, wenn Sie das Passwort vergessen haben.</p>',
    es: '¡Este enlace ya ha sido utilizado! <hr/> Puedes intentar <a href="/login"> iniciar sesión </a> con tu correo electrónico y contraseña, o puedes usar la opción de <a href="/password-recover">restablecimiento de contraseña</a> si no recuerdas la contraseña.</p>'
  },
  Deconectare: {
    en: "Logout",
    fr: "Déconnexion",
    it: "Disconnetti",
    pt: "Sair",
    el: "Αποσύνδεση",
    nl: "Uitloggen",
    de: "Abmelden",
    es: "Cerrar sesión"
  },
  "Notificări": {
    en: "Notifications",
    fr: "Notifications",
    it: "Notifiche",
    pt: "Notificações",
    el: "Ειδοποιήσεις",
    nl: "Meldingen",
    de: "Benachrichtigungen",
    es: "Notificaciones"
  },
  "Legat de sesiunea": {
    en: "Related to session",
    fr: "Lié à la session",
    it: "Relativo alla sessione",
    pt: "Relacionado à sessão",
    el: "Σχετικό με τη συνεδρία",
    nl: "Gerelateerd aan sessie",
    de: "Bezogen auf die Sitzung",
    es: "Relacionado con la sesión"
  },
  "Ca răspuns la": {
    en: "In reply to",
    fr: "En réponse à",
    it: "In risposta a",
    pt: "Em resposta a",
    el: "Σε απάντηση του",
    nl: "In antwoord op",
    de: "Als Antwort auf",
    es: "En respuesta a"
  },
  Răspunde: {
    en: "Reply",
    fr: "Répondre",
    it: "Rispondi",
    pt: "Responder",
    el: "Απάντηση",
    nl: "Beantwoorden",
    de: "Antworten",
    es: "Responder"
  },
  Subiect: {
    en: "Subject",
    fr: "Sujet",
    it: "Oggetto",
    pt: "Assunto",
    el: "Θέμα",
    nl: "Onderwerp",
    de: "Betreff",
    es: "Asunto"
  },
  "Trage fișierele aici sau folosește": {
    en: "Drag files here or use",
    fr: "Glissez les fichiers ici ou utilisez",
    it: "Trascina i file qui o usa",
    pt: "Arraste os arquivos aqui ou use",
    el: "Σύρετε τα αρχεία εδώ ή χρησιμοποιήστε",
    nl: "Sleep bestanden hierheen of gebruik",
    de: "Ziehen Sie die Dateien hierher oder verwenden Sie",
    es: "Arrastra los archivos aquí o usa"
  },
  Închide: {
    en: "Close",
    fr: "Fermer",
    it: "Chiudi",
    pt: "Fechar",
    el: "Κλείσιμο",
    nl: "Sluiten",
    de: "Schließen",
    es: "Cerrar"
  },
  "Momentan, site-ul Mentor+ se află în construcție, pentru a vă putea oferi o experiență îmbunătățită. Între timp, vă invităm să rămâneți informați în legătură cu ultimele noutăți ale proiectului prin intermediul rețelelor noastre de socializare: ": {
    en: "Currently, the Mentor+ website is under construction, in order to offer you an improved experience. In the meantime, we invite you to stay informed about the latest news of the project through our social networks: ",
    fr: "Actuellement, le site Mentor+ est en construction, afin de vous offrir une expérience améliorée. En attendant, nous vous invitons à rester informé des dernières nouvelles du projet via nos réseaux sociaux: ",
    it: "Attualmente, il sito Mentor+ è in costruzione, per offrirti un'esperienza migliorata. Nel frattempo, ti invitiamo a rimanere informato sulle ultime novità del progetto attraverso i nostri social network: ",
    pt: "Atualmente, o site do Mentor+ está em construção, para oferecer a você uma experiência aprimorada. Enquanto isso, convidamos você a se manter informado sobre as últimas novidades do projeto através de nossas redes sociais: ",
    el: "Αυτή τη στιγμή, ο ιστότοπος Mentor+ βρίσκεται υπό κατασκευή, προκειμένου να σας προσφέρουμε μια βελτιωμένη εμπειρία. Εν τω μεταξύ, σας προσκαλούμε να μείνετε ενημερωμένοι σχετικά με τα τελευταία νέα του έργου μέσω των κοινωνικών μας δικτύων: ",
    nl: "Momenteel is de Mentor+ website in aanbouw, om u een verbeterde ervaring te bieden. In de tussentijd nodigen we u uit om op de hoogte te blijven van het laatste nieuws over het project via onze sociale netwerken: ",
    de: "Derzeit befindet sich die Mentor+ Website im Aufbau, um Ihnen eine verbesserte Erfahrung zu bieten. In der Zwischenzeit laden wir Sie ein, über die neuesten Nachrichten des Projekts über unsere sozialen Netzwerke informiert zu bleiben: ",
    es: "Actualmente, el sitio web de Mentor+ está en construcción, para ofrecerte una mejor experiencia. Mientras tanto, te invitamos a mantenerte informado sobre las últimas novedades del proyecto a través de nuestras redes sociales: "
  },
  "Vă mulțumim pentru înțelegere!": {
    en: "Thank you for your understanding!",
    fr: "Merci pour votre compréhension!",
    it: "Grazie per la comprensione!",
    pt: "Obrigado pela compreensão!",
    el: "Σας ευχαριστούμε για την κατανόηση!",
    nl: "Bedankt voor uw begrip!",
    de: "Vielen Dank für Ihr Verständnis!",
    es: "¡Gracias por tu comprensión!"
  },
  "Ne găsiți pe:": {
    en: "Find us on:",
    fr: "Retrouvez-nous sur:",
    it: "Ci trovi su:",
    pt: "Encontre-nos em:",
    el: "Μας βρίσκετε στο:",
    nl: "Vind ons op:",
    de: "Finden Sie uns auf:",
    es: "Encuéntranos en:"
  },
  "Folosirea unui inel de stare pentru a ghida alegerile tale poate fi o modalitate jucăușă și creativă de a te conecta cu sentimentele tale.": {
    en: "Using a mood ring to guide your choices can be a playful and creative way to connect with your feelings.",
    fr: "Utiliser une bague d'humeur pour guider vos choix peut être un moyen ludique et créatif de vous connecter à vos sentiments.",
    it: "Utilizzare un anello d'umore per guidare le tue scelte può essere un modo giocoso e creativo per connetterti con i tuoi sentimenti.",
    pt: "Usar um anel de humor para guiar suas escolhas pode ser uma maneira lúdica e criativa de se conectar com seus sentimentos.",
    el: "Η χρήση ενός δαχτυλιδιού κατάστασης για να καθοδηγήσει τις επιλογές σας μπορεί να είναι ένα παιχνιδιάρικο και δημιουργικό τρόπος για να συνδεθείτε με τα συναισθήματά σας.",
    nl: "Het gebruik van een stemmingsring om je keuzes te begeleiden kan een speelse en creatieve manier zijn om contact te maken met je gevoelens.",
    de: "Die Verwendung eines Stimmungsrings zur Steuerung Ihrer Entscheidungen kann eine spielerische und kreative Möglichkeit sein, sich mit Ihren Gefühlen zu verbinden.",
    es: "Usar un anillo de humor para guiar tus elecciones puede ser una forma lúdica y creativa de conectarte con tus sentimientos."
  },
  "Este o metodă distractivă de a reflecta asupra modului în care te simți în acest moment.": {
    en: "It's a fun method to reflect on how you're feeling at the moment.",
    fr: "C'est un moyen amusant de réfléchir à ce que vous ressentez en ce moment.",
    it: "È un modo divertente per riflettere su come ti senti in questo momento.",
    pt: "É uma maneira divertida de refletir sobre como você está se sentindo agora.",
    el: "Είναι ένας διασκεδαστικός τρόπος για να αντικατοπτρίσετε πώς νιώθετε αυτή τη στιγμή.",
    nl: "Het is een leuke manier om na te denken over hoe je je op dit moment voelt.",
    de: "Es ist eine unterhaltsame Methode, um darüber nachzudenken, wie Sie sich gerade fühlen.",
    es: "Es un método divertido para reflexionar sobre cómo te sientes en este momento."
  },
  "Te rog, nu uita să-ți faci alegerile astăzi!": {
    en: "Please, don't forget to make your choices today!",
    fr: "N'oubliez pas de faire vos choix aujourd'hui!",
    it: "Per favore, non dimenticare di fare le tue scelte oggi!",
    pt: "Por favor, não se esqueça de fazer suas escolhas hoje!",
    el: "Παρακαλώ, μην ξεχάσετε να κάνετε τις επιλογές σας σήμερα!",
    nl: "Vergeet niet om vandaag je keuzes te maken!",
    de: "Bitte vergessen Sie nicht, heute Ihre Wahl zu treffen!",
    es: "¡Por favor, no olvides hacer tus elecciones hoy!"
  },
  "Închiderea inelelor în fiecare zi te ajută să urmărești echilibrul între a te bucura de spontaneitate și a cântări grijile tale.": {
    en: "Closing the rings every day helps you track the balance between enjoying spontaneity and weighing your worries.",
    fr: "Fermer les anneaux chaque jour vous aide à suivre l'équilibre entre profiter de la spontanéité et peser vos soucis.",
    it: "Chiudere gli anelli ogni giorno ti aiuta a tenere traccia dell'equilibrio tra goderti la spontaneità e pesare le tue preoccupazioni.",
    pt: "Fechar os anéis todos os dias ajuda você a acompanhar o equilíbrio entre desfrutar da espontaneidade e pesar suas preocupações.",
    el: "Η κλειστήρα των δαχτυλιδιών κάθε μέρα σας βοηθά να παρακολουθείτε την ισορροπία μεταξύ της απόλαυσης της αυθαιρεσίας και της ζύγισης των ανησυχιών σας.",
    nl: "Het sluiten van de ringen elke dag helpt je om het evenwicht tussen het genieten van spontaniteit en het afwegen van je zorgen bij te houden.",
    de: "Das tägliche Schließen der Ringe hilft Ihnen, das Gleichgewicht zwischen dem Genießen der Spontanität und dem Abwägen Ihrer Sorgen zu verfolgen.",
    es: "Cerrar los anillos todos los días te ayuda a mantener el equilibrio entre disfrutar de la espontaneidad y sopesar tus preocupaciones."
  },
  "Mulțumim că ți-ai împărtășit starea!": {
    en: "Thank you for sharing your mood!",
    fr: "Merci d'avoir partagé votre humeur!",
    it: "Grazie per aver condiviso il tuo umore!",
    pt: "Obrigado por compartilhar seu humor!",
    el: "Ευχαριστούμε που μοιραστήκατε τη διάθεσή σας!",
    nl: "Bedankt voor het delen van je stemming!",
    de: "Danke, dass Sie Ihre Stimmung geteilt haben!",
    es: "¡Gracias por compartir tu estado de ánimo!"
  },
  "Te rog asigură-te că ai selectat un răspuns pentru fiecare categorie.": {
    en: "Please make sure you have selected an answer for each category.",
    fr: "Veuillez vous assurer d'avoir sélectionné une réponse pour chaque catégorie.",
    it: "Assicurati di aver selezionato una risposta per ogni categoria.",
    pt: "Certifique-se de ter selecionado uma resposta para cada categoria.",
    el: "Βεβαιωθείτε ότι έχετε επιλέξει μια απάντηση για κάθε κατηγορία.",
    nl: "Zorg ervoor dat je voor elke categorie een antwoord hebt geselecteerd.",
    de: "Bitte stellen Sie sicher, dass Sie eine Antwort für jede Kategorie ausgewählt haben.",
    es: "Por favor, asegúrate de haber seleccionado una respuesta para cada categoría."
  },
  "Alegerile tale de stare au fost trimise cu succes!": {
    en: "Your mood choices have been successfully submitted!",
    fr: "Vos choix d'humeur ont été soumis avec succès!",
    it: "Le tue scelte di umore sono state inviate con successo!",
    pt: "Suas escolhas de humor foram enviadas com sucesso!",
    el: "Οι επιλογές σας διάθεσης έχουν υποβληθεί με επιτυχία!",
    nl: "Je stemmingkeuzes zijn succesvol ingediend!",
    de: "Ihre Stimmungswahl wurde erfolgreich übermittelt!",
    es: "¡Tus elecciones de estado de ánimo han sido enviadas con éxito!"
  },
  "Trimiterea alegerilor tale de stare a eșuat. Te rog încearcă din nou.": {
    en: "Sending your mood choices failed. Please try again.",
    fr: "L'envoi de vos choix d'humeur a échoué. Veuillez réessayer.",
    it: "L'invio delle tue scelte di umore è fallito. Riprova.",
    pt: "O envio de suas escolhas de humor falhou. Por favor, tente novamente.",
    el: "Η αποστολή των επιλογών διάθεσής σας απέτυχε. Παρακαλώ δοκιμάστε ξανά.",
    nl: "Het verzenden van je stemmingkeuzes is mislukt. Probeer het opnieuw.",
    de: "Das Senden Ihrer Stimmungswahl ist fehlgeschlagen. Bitte versuchen Sie es erneut.",
    es: "El envío de tus elecciones de estado de ánimo falló. Por favor, inténtalo de nuevo."
  },
  "Mută cursorul pentru a vedea mai multe răspunsuri": {
    en: "Move the slider to see more answers",
    fr: "Survolez pour voir plus de réponses",
    it: "Passa il mouse per vedere più risposte",
    pt: "Passe o mouse para ver mais respostas",
    el: "Κάντε κύλιση για να δείτε περισσότερες απαντήσεις",
    nl: "Beweeg de muis om meer antwoorden te zien",
    de: "Bewegen Sie den Schieberegler, um weitere Antworten zu sehen",
    es: "Mueve el cursor para ver más respuestas"
  },
  "Trimite alegerile": {
    en: "Submit choices",
    fr: "Envoyer les choix",
    it: "Invia scelte",
    pt: "Enviar escolhas",
    el: "Υποβολή επιλογών",
    nl: "Keuzes indienen",
    de: "Auswahl absenden",
    es: "Enviar elecciones"
  },
  "Tratează-te bine": {
    en: "Treat yourself well",
    fr: "Traitez-vous bien",
    it: "Trattati bene",
    pt: "Trate-se bem",
    el: "Φροντίστε τον εαυτό σας καλά",
    nl: "Zorg goed voor jezelf",
    de: "Behandle dich gut",
    es: "Trátate bien"
  },
  "Sunt foarte dur cu mine, mai ales când lucrurile merg prost.": {
    en: "I’m really tough on myself, especially when things go wrong.",
    fr: "Je suis vraiment dur avec moi-même, surtout quand les choses vont mal.",
    it: "Sono davvero duro con me stesso, soprattutto quando le cose vanno male.",
    pt: "Eu sou muito duro comigo mesmo, especialmente quando as coisas dão errado.",
    el: "Είμαι πολύ σκληρός με τον εαυτό μου, ειδικά όταν τα πράγματα πάνε στραβά.",
    nl: "Ik ben echt streng voor mezelf, vooral als dingen misgaan.",
    de: "Ich bin wirklich hart zu mir selbst, besonders wenn Dinge schief gehen.",
    es: "Soy muy duro conmigo mismo, especialmente cuando las cosas van mal."
  },
  "Uneori mă bat pe mine însumi pentru dificultăți.": {
    en: "I sometimes beat myself up over difficulties.",
    fr: "Je me fais parfois du mal à cause des difficultés.",
    it: "A volte mi colpevolizzo per le difficoltà.",
    pt: "Às vezes me culpo pelas dificuldades.",
    el: "Μερικές φορές κατηγορώ τον εαυτό μου για τις δυσκολίες.",
    nl: "Soms maak ik mezelf verwijten over moeilijkheden.",
    de: "Manchmal mache ich mir selbst Vorwürfe wegen Schwierigkeiten.",
    es: "A veces me castigo por las dificultades."
  },
  "Sunt un amestec - uneori sunt autocritic, alteori sunt bine cu mine.": {
    en: "I’m a mix – sometimes I’m self-critical, other times I’m okay with myself.",
    fr: "Je suis un mélange - parfois je suis autocritique, d'autres fois je suis bien avec moi-même.",
    it: "Sono un misto - a volte sono autocritico, altre volte sto bene con me stesso.",
    pt: "Eu sou uma mistura - às vezes sou autocrítico, outras vezes estou bem comigo mesmo.",
    el: "Είμαι ένα μείγμα - μερικές φορές είμαι αυτοκριτικός, άλλες φορές είμαι εντάξει με τον εαυτό μου.",
    nl: "Ik ben een mix - soms ben ik zelfkritisch, andere keren ben ik oké met mezelf.",
    de: "Ich bin ein Mix - manchmal bin ich selbstkritisch, manchmal bin ich zufrieden mit mir.",
    es: "Soy una mezcla - a veces soy autocrítico, otras veces estoy bien conmigo mismo."
  },
  "În general, sunt suportiv și înțelegător cu mine când trec prin momente grele.": {
    en: "I’m generally supportive and understanding towards myself when times get tough.",
    fr: "Je suis généralement compréhensif et soutenant envers moi-même quand les temps sont durs.",
    it: "In generale, sono di supporto e comprensivo con me stesso quando i tempi sono difficili.",
    pt: "Geralmente sou solidário e compreensivo comigo mesmo quando os tempos são difíceis.",
    el: "Γενικά, είμαι υποστηρικτικός και κατανοητός προς τον εαυτό μου όταν οι καιροί είναι δύσκολοι.",
    nl: "Over het algemeen ben ik ondersteunend en begripvol naar mezelf als het moeilijk wordt.",
    de: "Im Allgemeinen unterstütze und verstehe ich mich selbst, wenn es hart auf hart kommt.",
    es: "En general, soy solidario y comprensivo conmigo mismo cuando los tiempos se ponen difíciles."
  },
  "Indiferent de ce se întâmplă, întotdeauna mă tratez cu bunătate și respect.": {
    en: "No matter what happens, I always treat myself with kindness and respect.",
    fr: "Peu importe ce qui arrive, je me traite toujours avec gentillesse et respect.",
    it: "Qualunque cosa accada, mi tratto sempre con gentilezza e rispetto.",
    pt: "Não importa o que aconteça, sempre me trato com bondade e respeito.",
    el: "Ό,τι κι αν συμβεί, πάντα φέρομαι στον εαυτό μου με καλοσύνη και σεβασμό.",
    nl: "Wat er ook gebeurt, ik behandel mezelf altijd met vriendelijkheid en respect.",
    de: "Egal was passiert, ich behandle mich immer mit Freundlichkeit und Respekt.",
    es: "No importa lo que pase, siempre me trato con amabilidad y respeto."
  },
  "Simte-te conectat": {
    en: "Feel connected",
    fr: "Sentez-vous connecté",
    it: "Sentiti connesso",
    pt: "Sinta-se conectado",
    el: "Νιώσε συνδεδεμένος",
    nl: "Voel je verbonden",
    de: "Fühle dich verbunden",
    es: "Siéntete conectado"
  },
  "Mă simt complet singur, ca și cum nu aș avea pe nimeni la care să mă întorc.": {
    en: "I feel totally alone, like I have no one to turn to.",
    fr: "Je me sens totalement seul, comme si je n'avais personne vers qui me tourner.",
    it: "Mi sento completamente solo, come se non avessi nessuno a cui rivolgermi.",
    pt: "Eu me sinto totalmente sozinho, como se não tivesse ninguém a quem recorrer.",
    el: "Νιώθω εντελώς μόνος, σαν να μην έχω κανέναν να στραφώ.",
    nl: "Ik voel me helemaal alleen, alsof ik niemand heb om naar toe te gaan.",
    de: "Ich fühle mich total allein, als hätte ich niemanden, an den ich mich wenden kann.",
    es: "Me siento totalmente solo, como si no tuviera a nadie a quien recurrir."
  },
  "Adesea simt că nu am mulți oameni în colțul meu.": {
    en: "It often feels like I don’t have many people in my corner.",
    fr: "J'ai souvent l'impression de ne pas avoir beaucoup de gens dans mon coin.",
    it: "Spesso mi sembra di non avere molte persone dalla mia parte.",
    pt: "Frequentemente sinto que não tenho muitas pessoas do meu lado.",
    el: "Συχνά νιώθω ότι δεν έχω πολλούς ανθρώπους στο πλευρό μου.",
    nl: "Het voelt vaak alsof ik niet veel mensen aan mijn zijde heb.",
    de: "Es fühlt sich oft an, als hätte ich nicht viele Menschen auf meiner Seite.",
    es: "A menudo siento que no tengo a muchas personas en mi esquina."
  },
  "Uneori mă simt susținut, alteori mă simt singur.": {
    en: "Sometimes I feel supported, other times I feel alone.",
    fr: "Parfois je me sens soutenu, d'autres fois je me sens seul.",
    it: "A volte mi sento supportato, altre volte mi sento solo.",
    pt: "Às vezes me sinto apoiado, outras vezes me sinto sozinho.",
    el: "Μερικές φορές νιώθω υποστηριγμένος, άλλες φορές νιώθω μόνος.",
    nl: "Soms voel ik me gesteund, andere keren voel ik me alleen.",
    de: "Manchmal fühle ich mich unterstützt, manchmal fühle ich mich allein.",
    es: "A veces me siento apoyado, otras veces me siento solo."
  },
  "De obicei simt că am sprijin din partea prietenilor sau familiei.": {
    en: "I usually feel like I have support from friends or family.",
    fr: "Je sens généralement que j'ai le soutien de mes amis ou de ma famille.",
    it: "Di solito sento di avere il supporto di amici o familiari.",
    pt: "Geralmente sinto que tenho o apoio de amigos ou familiares.",
    el: "Συνήθως νιώθω ότι έχω την υποστήριξη φίλων ή οικογένειας.",
    nl: "Ik voel meestal dat ik steun heb van vrienden of familie.",
    de: "Normalerweise habe ich das Gefühl, dass ich Unterstützung von Freunden oder Familie habe.",
    es: "Por lo general, siento que tengo el apoyo de amigos o familiares."
  },
  "Întotdeauna știu că am oameni care mă susțin și care țin la mine.": {
    en: "I always know I have people who back me up and care about me.",
    fr: "Je sais toujours que j'ai des gens qui me soutiennent et qui se soucient de moi.",
    it: "So sempre di avere persone che mi sostengono e si prendono cura di me.",
    pt: "Eu sempre sei que tenho pessoas que me apoiam e se preocupam comigo.",
    el: "Ξέρω πάντα ότι έχω ανθρώπους που με υποστηρίζουν και νοιάζονται για μένα.",
    nl: "Ik weet altijd dat ik mensen heb die me steunen en om me geven.",
    de: "Ich weiß immer, dass ich Menschen habe, die mich unterstützen und sich um mich kümmern.",
    es: "Siempre sé que tengo personas que me respaldan y se preocupan por mí."
  },
  "Rămâi prezent": {
    en: "Stay present",
    fr: "Restez présent",
    it: "Resta presente",
    pt: "Fique presente",
    el: "Μείνετε παρόντες",
    nl: "Blijf aanwezig",
    de: "Bleib präsent",
    es: "Permanece presente"
  },
  "Sunt întotdeauna copleșit de gândurile și sentimentele mele.": {
    en: "I’m always overwhelmed by my thoughts and feelings.",
    fr: "Je suis toujours submergé par mes pensées et mes sentiments.",
    it: "Sono sempre sopraffatto dai miei pensieri e sentimenti.",
    pt: "Estou sempre sobrecarregado por meus pensamentos e sentimentos.",
    el: "Είμαι πάντα συγκλονισμένος από τις σκέψεις και τα συναισθήματά μου.",
    nl: "Ik ben altijd overweldigd door mijn gedachten en gevoelens.",
    de: "Ich bin immer überwältigt von meinen Gedanken und Gefühlen.",
    es: "Siempre estoy abrumado por mis pensamientos y sentimientos."
  },
  "Îmi este greu să-mi păstrez mintea la ceea ce se întâmplă acum.": {
    en: "It’s hard for me to keep my mind on what’s happening right now.",
    fr: "Il m'est difficile de garder l'esprit sur ce qui se passe en ce moment.",
    it: "È difficile per me mantenere la mente su ciò che sta accadendo in questo momento.",
    pt: "É difícil para mim manter a mente no que está acontecendo agora.",
    el: "Είναι δύσκολο για μένα να διατηρήσω το μυαλό μου σε αυτό που συμβαίνει αυτή τη στιγμή.",
    nl: "Het is moeilijk voor mij om mijn gedachten bij te houden wat er nu gebeurt.",
    de: "Es fällt mir schwer, meine Gedanken auf das zu konzentrieren, was gerade passiert.",
    es: "Me cuesta mantener la mente en lo que está sucediendo ahora."
  },
  "Reușesc să rămân prezent uneori, dar deseori pierd urma.": {
    en: "I manage to stay present sometimes, but often lose track.",
    fr: "Je parviens parfois à rester présent, mais je perds souvent la trace.",
    it: "A volte riesco a rimanere presente, ma spesso perdo la traccia.",
    pt: "Às vezes consigo estar presente, mas muitas vezes perco a noção.",
    el: "Καταφέρνω να μείνω παρών μερικές φορές, αλλά συχνά χάνω την πορεία.",
    nl: "Ik slaag er soms in om aanwezig te blijven, maar raak vaak het spoor bijster.",
    de: "Manchmal schaffe ich es, präsent zu bleiben, aber oft verliere ich den Faden.",
    es: "A veces logro mantenerme presente, pero a menudo pierdo el hilo."
  },
  "De obicei, pot să mă concentrez pe prezent fără să fiu distras de gândurile mele.": {
    en: "I can usually focus on the present without getting sidetracked by my thoughts.",
    fr: "Je peux généralement me concentrer sur le présent sans être distrait par mes pensées.",
    it: "Di solito riesco a concentrarmi sul presente senza essere distratto dai miei pensieri.",
    pt: "Geralmente consigo me concentrar no presente sem me deixar desviar pelos meus pensamentos.",
    el: "Συνήθως μπορώ να επικεντρωθώ στο παρόν χωρίς να αποσπάται η προσοχή μου από τις σκέψεις μου.",
    nl: "Ik kan me meestal op het heden concentreren zonder afgeleid te worden door mijn gedachten.",
    de: "Ich kann mich normalerweise auf die Gegenwart konzentrieren, ohne mich von meinen Gedanken ablenken zu lassen.",
    es: "Por lo general, puedo concentrarme en el presente sin distraerme con mis pensamientos."
  },
  "Sunt foarte bun la a mă concentra și a nu lăsa gândurile să preia controlul.": {
    en: "I’m really good at keeping focused and not letting my thoughts take over.",
    fr: "Je suis vraiment bon pour rester concentré et ne pas laisser mes pensées prendre le dessus.",
    it: "Sono davvero bravo a rimanere concentrato e a non lasciare che i miei pensieri prendano il sopravvento.",
    pt: "Eu sou muito bom em manter o foco e não deixar meus pensamentos assumirem o controle.",
    el: "Είμαι πολύ καλός στο να παραμένω συγκεντρωμένος και να μην αφήνω τις σκέψεις μου να επικρατήσουν.",
    nl: "Ik ben heel goed in gefocust blijven en mijn gedachten niet de overhand laten nemen.",
    de: "Ich bin sehr gut darin, mich zu konzentrieren und meine Gedanken nicht die Kontrolle übernehmen zu lassen.",
    es: "Soy muy bueno en mantenerme enfocado y no dejar que mis pensamientos se apoderen."
  },
  "Simte-te bine sau rău": {
    en: "Feel up or down",
    fr: "Se sentir bien ou mal",
    it: "Sentirsi bene o male",
    pt: "Sentir-se bem ou mal",
    el: "Νιώσε καλά ή άσχημα",
    nl: "Voel je goed of slecht",
    de: "Fühle dich gut oder schlecht",
    es: "Siéntete bien o mal"
  },
  "Majoritatea zilelor mă simt rău și nu am energia necesară.": {
    en: "Most days I feel down and don’t have the energy I need.",
    fr: "La plupart des jours, je me sens déprimé et je n'ai pas l'énergie nécessaire.",
    it: "La maggior parte dei giorni mi sento giù e non ho l'energia necessaria.",
    pt: "Na maioria dos dias, me sinto deprimido e não tenho a energia necessária.",
    el: "Τις περισσότερες μέρες νιώθω πεσμένος και δεν έχω την ενέργεια που χρειάζομαι.",
    nl: "De meeste dagen voel ik me down en heb ik niet de energie die ik nodig heb.",
    de: "An den meisten Tagen fühle ich mich niedergeschlagen und habe nicht die Energie, die ich brauche.",
    es: "La mayoría de los días me siento deprimido y no tengo la energía que necesito."
  },
  "Adesea mă lupt să mă simt bine și motivat.": {
    en: "I often struggle to feel good and motivated.",
    fr: "Je me bats souvent pour me sentir bien et motivé.",
    it: "Spesso faccio fatica a sentirmi bene e motivato.",
    pt: "Frequentemente luto para me sentir bem e motivado.",
    el: "Συχνά παλεύω να νιώσω καλά και να έχω κίνητρο.",
    nl: "Ik worstel vaak om me goed en gemotiveerd te voelen.",
    de: "Ich habe oft Mühe, mich gut und motiviert zu fühlen.",
    es: "A menudo lucho por sentirme bien y motivado."
  },
  "Zilele mele sunt un amestec - uneori bine, uneori rău.": {
    en: "My days are a mix – sometimes up, sometimes down.",
    fr: "Mes journées sont un mélange - parfois en haut, parfois en bas.",
    it: "Le mie giornate sono un misto - a volte su, a volte giù.",
    pt: "Meus dias são uma mistura - às vezes para cima, às vezes para baixo.",
    el: "Οι μέρες μου είναι ένα μείγμα - μερικές φορές πάνω, μερικές φορές κάτω.",
    nl: "Mijn dagen zijn een mix - soms op, soms neer.",
    de: "Meine Tage sind ein Mix – manchmal gut, manchmal schlecht.",
    es: "Mis días son una mezcla: a veces bien, a veces mal."
  },
  "În general mă simt pozitiv și energic.": {
    en: "I generally feel positive and energetic.",
    fr: "Je me sens généralement positif et énergique.",
    it: "In generale mi sento positivo ed energico.",
    pt: "Geralmente me sinto positivo e energizado.",
    el: "Γενικά νιώθω θετικός και ενεργητικός.",
    nl: "Ik voel me over het algemeen positief en energiek.",
    de: "Ich fühle mich im Allgemeinen positiv und energiegeladen.",
    es: "En general me siento positivo y enérgico."
  },
  "Mă simt grozav și gata în fiecare zi.": {
    en: "I feel awesome and ready every day.",
    fr: "Je me sens génial et prêt chaque jour.",
    it: "Mi sento fantastico e pronto ogni giorno.",
    pt: "Eu me sinto incrível e pronto todos os dias.",
    el: "Νιώθω υπέροχα και έτοιμος κάθε μέρα.",
    nl: "Ik voel me geweldig en klaar elke dag.",
    de: "Ich fühle mich großartig und bereit an jedem Tag.",
    es: "Me siento increíble y listo cada día."
  },
  "Implică-te": {
    en: "Get involved",
    fr: "Impliquez-vous",
    it: "Coinvolgiti",
    pt: "Envolva-se",
    el: "Συμμετέχετε",
    nl: "Doe mee",
    de: "Engagier dich",
    es: "Involúcrate"
  },
  "Nu simt că fac prea multe; mă țin doar pentru mine.": {
    en: "I don’t feel like doing much; I just keep to myself.",
    fr: "Je n'ai pas envie de faire grand-chose; je me contente de rester dans mon coin.",
    it: "Non mi va di fare molto; mi limito a stare per conto mio.",
    pt: "Não tenho vontade de fazer muito; eu fico só para mim.",
    el: "Δεν νιώθω ότι κάνω πολλά; απλά κρατώ για τον εαυτό μου.",
    nl: "Ik heb niet veel zin om iets te doen; ik houd het gewoon voor mezelf.",
    de: "Ich habe keine Lust, viel zu tun; ich bleibe einfach für mich.",
    es: "No tengo ganas de hacer mucho; simplemente me quedo solo."
  },
  "Adesea mă abțin de la a mă implica în lucruri.": {
    en: "I often hold back from getting involved in things.",
    fr: "Je m'abstiens souvent de m'impliquer dans les choses.",
    it: "Spesso mi trattengo dal coinvolgermi nelle cose.",
    pt: "Frequentemente me abstenho de me envolver nas coisas.",
    el: "Συχνά αποφεύγω να συμμετέχω σε πράγματα.",
    nl: "Ik houd me vaak terug om me in dingen te betrekken.",
    de: "Ich halte mich oft zurück, mich in Dinge einzumischen.",
    es: "A menudo me abstengo de involucrarme en cosas."
  },
  "Mă implic uneori, dar nu constant.": {
    en: "I get involved sometimes, but not consistently.",
    fr: "Je m'implique parfois, mais pas constamment.",
    it: "A volte mi coinvolgo, ma non costantemente.",
    pt: "Eu me envolvo às vezes, mas não de forma consistente.",
    el: "Συμμετέχω μερικές φορές, αλλά όχι συνεχώς.",
    nl: "Ik raak soms betrokken, maar niet consequent.",
    de: "Ich engagiere mich manchmal, aber nicht konsequent.",
    es: "Me involucro a veces, pero no de manera constante."
  },
  "Sunt destul de angajat în ceea ce fac zilnic.": {
    en: "I’m pretty engaged in what I do daily.",
    fr: "Je suis assez engagé dans ce que je fais quotidiennement.",
    it: "Sono abbastanza impegnato in quello che faccio ogni giorno.",
    pt: "Estou bastante envolvido no que faço diariamente.",
    el: "Είμαι αρκετά αφοσιωμένος σε ό,τι κάνω καθημερινά.",
    nl: "Ik ben behoorlijk betrokken bij wat ik dagelijks doe.",
    de: "Ich bin ziemlich engagiert in dem, was ich täglich tue.",
    es: "Estoy bastante involucrado en lo que hago diariamente."
  },
  "Sunt complet implicat, în fiecare zi, indiferent de ceea ce fac.": {
    en: "I’m all in, every day, no matter what I’m doing.",
    fr: "Je suis totalement impliqué, chaque jour, quoi que je fasse.",
    it: "Sono completamente coinvolto, ogni giorno, indipendentemente da quello che faccio.",
    pt: "Estou totalmente envolvido, todos os dias, não importa o que estou fazendo.",
    el: "Είμαι πλήρως αφοσιωμένος, κάθε μέρα, ανεξάρτητα από το τι κάνω.",
    nl: "Ik ben elke dag volledig betrokken, ongeacht wat ik doe.",
    de: "Ich bin jeden Tag voll dabei, egal was ich tue.",
    es: "Estoy completamente involucrado, todos los días, sin importar lo que esté haciendo."
  },
  "Gestionează comportamentul": {
    en: "Manage behavior",
    fr: "Gérer le comportement",
    it: "Gestire il comportamento",
    pt: "Gerenciar comportamento",
    el: "Διαχειριστείτε τη συμπεριφορά",
    nl: "Beheer gedrag",
    de: "Verhalten managen",
    es: "Gestionar el comportamiento"
  },
  "Adesea mă găsesc în necazuri sau acționând necorespunzător.": {
    en: "I often find myself in trouble or acting out.",
    fr: "Je me trouve souvent en difficulté ou en train de mal agir.",
    it: "Mi trovo spesso nei guai o agendo in modo inappropriato.",
    pt: "Frequentemente me encontro em apuros ou agindo de forma inadequada.",
    el: "Συχνά βρίσκομαι σε μπελάδες ή ενεργώντας ακατάλληλα.",
    nl: "Ik bevind me vaak in de problemen of gedraag me slecht.",
    de: "Ich gerate oft in Schwierigkeiten oder benehme mich unangemessen.",
    es: "A menudo me meto en problemas o me comporto mal."
  },
  "Sub stres, uneori acționez în moduri pe care le regret mai târziu.": {
    en: "Under stress, I sometimes act in ways I later regret.",
    fr: "Sous le stress, j'agis parfois de manière que je regrette plus tard.",
    it: "Sotto stress, a volte agisco in modi di cui mi pento in seguito.",
    pt: "Sob estresse, às vezes ajo de maneiras que depois me arrependo.",
    el: "Υπό πίεση, μερικές φορές ενεργώ με τρόπους που αργότερα μετανιώνω.",
    nl: "Onder stress handel ik soms op manieren waar ik later spijt van heb.",
    de: "Unter Stress handle ich manchmal auf eine Weise, die ich später bereue.",
    es: "Bajo estrés, a veces actúo de maneras que luego lamento."
  },
  "Comportamentul meu este variabil, în funcție de situație.": {
    en: "My behavior is hit or miss, depending on the situation.",
    fr: "Mon comportement est aléatoire, selon la situation.",
    it: "Il mio comportamento è altalenante, a seconda della situazione.",
    pt: "Meu comportamento é incerto, dependendo da situação.",
    el: "Η συμπεριφορά μου είναι μεταβλητή, ανάλογα με την κατάσταση.",
    nl: "Mijn gedrag is wisselvallig, afhankelijk van de situatie.",
    de: "Mein Verhalten ist mal so, mal so, je nach Situation.",
    es: "Mi comportamiento es irregular, dependiendo de la situación."
  },
  "În general, gestionez lucrurile calm și mă mențin liniștit.": {
    en: "I generally handle things calmly and keep myself cool.",
    fr: "En général, je gère les choses calmement et je reste calme.",
    it: "In generale, gestisco le cose con calma e rimango tranquillo.",
    pt: "Geralmente lido com as coisas com calma e mantenho a calma.",
    el: "Γενικά, διαχειρίζομαι τα πράγματα με ηρεμία και παραμένω ψύχραιμος.",
    nl: "Ik handel over het algemeen kalm en blijf rustig.",
    de: "Ich gehe die Dinge normalerweise ruhig an und bleibe gelassen.",
    es: "En general, manejo las cosas con calma y me mantengo sereno."
  },
  "Întotdeauna reușesc să acționez responsabil și să fac alegeri bune, chiar și când este dificil.": {
    en: "I always manage to act responsibly and make good choices, even when it’s tough.",
    fr: "Je parviens toujours à agir de manière responsable et à faire de bons choix, même quand c'est difficile.",
    it: "Riesco sempre a comportarmi in modo responsabile e a fare buone scelte, anche quando è difficile.",
    pt: "Sempre consigo agir com responsabilidade e fazer boas escolhas, mesmo quando é difícil.",
    el: "Πάντα καταφέρνω να ενεργώ υπεύθυνα και να κάνω καλές επιλογές, ακόμα και όταν είναι δύσκολο.",
    nl: "Ik slaag er altijd in om verantwoordelijk te handelen en goede keuzes te maken, zelfs als het moeilijk is.",
    de: "Ich schaffe es immer, verantwortungsvoll zu handeln und gute Entscheidungen zu treffen, auch wenn es schwer ist.",
    es: "Siempre logro actuar de manera responsable y tomar buenas decisiones, incluso cuando es difícil."
  },
  "Proiectul Mentor+ a primit finanțare de la Programul Erasmus+ al Uniunii Europene sub numărul acordului nr: 2021-2-BE05-KA220-YOU-000050037.": {
    en: "Mentor+ Project has received funding from the European Union’s Erasmus+ Programme under agreement number no: 2021-2-BE05-KA220-YOU-000050037.",
    fr: "Le projet Mentor+ a été financé par le programme Erasmus+ de l'Union européenne dans le cadre de l'accord de subvention n°: 2021-2-BE05-KA220-YOU-000050037.",
    it: "Il progetto Mentor+ è stato finanziato dal Programma Erasmus+ dell'Unione Europea ai sensi dell'accordo di sovvenzione n°: 2021-2-BE05-KA220-YOU-000050037.",
    pt: "O projeto Mentor+ foi financiado pelo Programa Erasmus+ da União Europeia sob o acordo de subvenção nº: 2021-2-BE05-KA220-YOU-000050037.",
    el: "Το έργο Mentor+ χρηματοδοτήθηκε από το Πρόγραμμα Erasmus+ της Ευρωπαϊκής Ένωσης στο πλαίσιο της συμφωνίας επιχορήγησης αριθ.: 2021-2-BE05-KA220-YOU-000050037.",
    nl: "Het Mentor+ project is gefinancierd door het Erasmus+ Programma van de Europese Unie onder subsidieovereenkomst nr: 2021-2-BE05-KA220-YOU-000050037.",
    de: "Das Mentor+-Projekt hat eine Förderung aus dem Erasmus+-Programm der Europäischen Union unter der Vertragsnummer 2021-2-BE05-KA220-YOU-000050037 erhalten.",
    es: "El Proyecto Mentor+ ha recibido financiación del Programa Erasmus+ de la Unión Europea bajo el acuerdo nº: 2021-2-BE05-KA220-YOU-000050037."
  },
  "Sprijinul Comisiei Europene pentru producerea acestei aplicații mobile și a publicațiilor din aceasta nu constituie o aprobare a conținutului care reflectă doar punctul de vedere al autorului (autorilor), iar Comisia nu poate fi făcută responsabilă pentru orice utilizare care poate fi făcută informatiile continute de acesta.": {
    en: "The European Commission support for the production of this mobile application and the publications therein does not constitute an endorsement of the contents which reflect the views only of the author(s), and the Commission cannot be held responsible for any use which may be made of the information contained therein.",
    fr: "Le soutien de la Commission européenne à la production de cette application mobile ne constitue pas une approbation du contenu qui reflète uniquement les points de vue des auteurs, et la Commission ne peut être tenue responsable de l'utilisation qui pourrait être faite des informations qui y sont contenues.",
    it: "Il sostegno della Commissione europea per la produzione di questa applicazione mobile non costituisce un'approvazione dei contenuti che riflette solo il punto di vista degli autori, e la Commissione non può essere ritenuta responsabile per qualsiasi uso che può essere fatto delle informazioni in esso contenute.",
    pt: "O apoio da Comissão Europeia à produção desta aplicação móvel não constitui uma aprovação dos conteúdos que reflete apenas as opiniões dos autores, e a Comissão não pode ser responsabilizada por qualquer uso que possa ser feito das informações nele contidas.",
    el: "Η υποστήριξη της Ευρωπαϊκής Επιτροπής για την παραγωγή αυτής της κινητής εφαρμογής δεν αποτελεί έγκριση των περιεχομένων που αντικατοπτρίζουν μόνο τις απόψεις των συγγραφέων, και η Επιτροπή δεν μπορεί να θεωρηθεί υπεύθυνη για οποιαδήποτε χρήση π ενδέχεται να γίνει των πληροφοριών που περιέχονται σε αυτό.",
    nl: "De steun van de Europese Commissie voor de productie van deze mobiele applicatie vormt geen goedkeuring van de inhoud die alleen de standpunten van de auteurs weerspiegelt, en de Commissie kan niet verantwoordelijk worden gehouden voor elk gebruik dat van de informatie daarin kan worden gemaakt.",
    de: "Die Unterstützung der Europäischen Kommission bei der Produktion dieser mobilen Anwendung stellt keine Billigung der Inhalte dar, die nur die Ansichten der Autor(inn)en widerspiegeln, und die Kommission kann nicht für die Nutzung der darin enthaltenen Informationen haftbar gemacht werden.",
    es: "El apoyo de la Comisión Europea para la producción de esta aplicación móvil no constituye un respaldo de los contenidos, que reflejan únicamente las opiniones de los autores, y la Comisión no puede ser considerada responsable de cualquier uso que se haga de la información contenida en ella."
  },
  "Mentoratul este orice formă de învățare socială în care cineva cu mai multă experiență (un mentor) ajută la ghidarea învățării și creșterii cuiva cu mai puțină experiență (un mentorat). Este o relație de învățare care îi ajută pe oameni să își asume propria dezvoltare, să-și elibereze potențialul și să obțină rezultate pe care le prețuiesc.": {
    "en": "Mentoring is any form of social learning where someone with more experience (a mentor) helps guide the learning and growth of someone with less experience (a mentee). It is a learning relationship which helps people to take charge of their own development, to release their potential and to achieve results which they value.",
    "fr": "Le mentorat est une forme d'apprentissage social dans laquelle une personne ayant plus d'expérience (un mentor) aide à guider l'apprentissage et la croissance d'une personne ayant moins d'expérience (un mentoré). C'est une relation d'apprentissage qui aide les gens à prendre en charge leur propre développement, à libérer leur potentiel et à obtenir des résultats qu'ils apprécient.",
    "it": "Il mentoring è una qualsiasi forma di apprendimento sociale in cui qualcuno con più esperienza (un mentore) aiuta a guidare l'apprendimento e la crescita di qualcuno con meno esperienza (un mentee). È una relazione di apprendimento che aiuta le persone a prendere in mano il proprio sviluppo, a sbloccare il proprio potenziale e a raggiungere risultati che valorizzano.",
    "pt": "Mentoria é qualquer forma de aprendizagem social na qual alguém com mais experiência (um mentor) ajuda a orientar a aprendizagem e o crescimento de alguém com menos experiência (um mentorado). É uma relação de aprendizagem que ajuda as pessoas a assumir o controle do seu próprio desenvolvimento, desbloquear o seu potencial e alcançar resultados que valorizam.",
    "el": "Το μέντορας είναι κάθε μορφή κοινωνικής μάθησης στην οποία κάποιος με περισσότερη εμπειρία (ένας μέντορας) βοηθά στην καθοδήγηση της μάθησης και της ανάπτυξης κάποιου με λιγότερη εμπειρία (ένας μέντορας). Είναι μια σχέση μάθησης που βοηθά τους ανθρώπους να αναλάβουν τη δική τους ανάπτυξη, να ξεκλειδώσουν το δυναμικό τους και να επιτύχουν αποτελέσματα που εκτιμούν.",
    "nl": "Mentoring is elke vorm van sociaal leren waarbij iemand met meer ervaring (een mentor) helpt bij het begeleiden van het leren en de groei van iemand met minder ervaring (een mentee). Het is een leerrelatie die mensen helpt de leiding te nemen over hun eigen ontwikkeling, hun potentieel te ontsluiten en resultaten te behalen die ze waarderen.",
    "de": "Mentoring ist jede Form des sozialen Lernens, bei der jemand mit mehr Erfahrung (ein Mentor) jemandem mit weniger Erfahrung (ein Mentee) hilft, zu lernen und zu wachsen. Es ist eine Lernbeziehung, die Menschen hilft, ihre eigene Entwicklung in die Hand zu nehmen, ihr Potenzial freizusetzen und Ergebnisse zu erzielen, die sie schätzen.",
    "es": "El mentoring es cualquier forma de aprendizaje social en la que alguien con más experiencia (un mentor) ayuda a guiar el aprendizaje y el crecimiento de alguien con menos experiencia (un mentee). Es una relación de aprendizaje que ayuda a las personas a hacerse cargo de su propio desarrollo, liberar su potencial y alcanzar resultados que valoran."
  },
  "Există multe definiții ale mentoratului. Cu toate acestea, definiția cea mai succintă este atunci când cineva își împărtășește cunoștințele, abilitățile și experiența pentru a ajuta o altă persoană să progreseze. Acest progres ar putea fi profesional sau personal, deși, având în vedere echilibrul muncă/viață, este adesea luată în considerare încrucișarea dintre cele două. Mentorul acționează ca un consilier de încredere și un model de urmat, împărtășindu-și expertiza și ajutându-l pe mentor să-și dezvolte abilitățile, cunoștințele și abilitățile.": {
    "en": "There are many definitions of mentoring. However, the most succinct definition is when someone shares their knowledge, skills, and experience to help another person to progress. This progress could be professional or personal, although given the work/life balance, the crossover between the two is often considered. The mentor acts as a trusted advisor and role model, sharing his/her expertise and helping the mentee develop their skills, knowledge, and abilities.",
    "fr": "Il existe de nombreuses définitions du mentorat. Cependant, la définition la plus succincte est lorsque quelqu'un partage ses connaissances, ses compétences et son expérience pour aider une autre personne à progresser. Ce progrès peut être professionnel ou personnel, bien que, compte tenu de l'équilibre travail/vie, il soit souvent considéré comme l'intersection des deux. Le mentor agit comme un conseiller de confiance et un modèle à suivre, partageant son expertise et aidant le mentoré à développer ses compétences, ses connaissances et ses capacités.",
    "it": "Esistono molte definizioni di mentoring. Tuttavia, la definizione più succinta è quando qualcuno condivide le proprie conoscenze, competenze ed esperienze per aiutare un'altra persona a progredire. Questo progresso potrebbe essere professionale o personale, anche se, data l'equilibrio tra lavoro e vita, spesso viene considerata l'intersezione delle due. Il mentore agisce come un consulente di fiducia e un modello da seguire, condividendo la propria esperienza e aiutando il mentee a sviluppare le proprie competenze, conoscenze e abilità.",
    "pt": "Existem muitas definições de mentoria. No entanto, a definição mais sucinta é quando alguém compartilha seus conhecimentos, habilidades e experiência para ajudar outra pessoa a progredir. Esse progresso pode ser profissional ou pessoal, embora, dada a balança entre trabalho e vida, seja frequentemente considerada a interseção das duas. O mentor age como um conselheiro de confiança e modelo a ser seguido, compartilhando sua experiência e ajudando o mentorado a desenvolver suas habilidades, conhecimentos e capacidades.",
    "el": "Υπάρχουν πολλοί ορισμοί του μέντορα. Ωστόσο, ο πιο συνοπτικός ορισμός είναι όταν κάποιος μοιράζεται τις γνώσεις, τις δεξιότητες και την εμπειρία του για να βοηθήσει κάποιον άλλο να προχωρήσει. Αυτή η πρόοδος θα μπορούσε να είναι επαγγελματική ή προσωπική, αν και, δεδομένης της ισορροπίας μεταξύ εργασίας και ζωής, συχνά θεωρείται η τομή των δύο. Ο μέντορας δρα ως ένας αξιόπιστος σύμβουλος και υπόδειγμα, μοιράζοντας την εμπειρία του και βοηθώντας τον μέντορα να αναπτύξει τις δεξιότητές του, τις γνώσεις και τις ικανότητές του.",
    "nl": "Er zijn veel definities van mentoring. De meest beknopte definitie is echter wanneer iemand zijn kennis, vaardigheden en ervaring deelt om een andere persoon te helpen vooruit te komen. Deze vooruitgang kan professioneel of persoonlijk zijn, hoewel, gezien de balans tussen werk en privéleven, vaak de doorsnede van de twee wordt genoemd. De mentor treedt op als een vertrouwde adviseur en rolmodel, deelt zijn expertise en helpt de mentee zijn vaardigheden, kennis en capaciteiten te ontwikkelen.",
    "de": "Es gibt viele Definitionen von Mentoring. Die knappste Definition lautet jedoch, wenn jemand sein Wissen, seine Fähigkeiten und Erfahrungen teilt, um einer anderen Person zu helfen, Fortschritte zu machen. Dieser Fortschritt kann beruflich oder persönlich sein, wobei oft die Überschneidung zwischen beiden berücksichtigt wird. Der Mentor fungiert als vertrauenswürdiger Berater und Vorbild, teilt sein Fachwissen und hilft dem Mentee, seine Fähigkeiten, Kenntnisse und Fertigkeiten zu entwickeln.",
    "es": "Existen muchas definiciones de mentoring. Sin embargo, la más concisa es cuando alguien comparte sus conocimientos, habilidades y experiencia para ayudar a otra persona a progresar. Este progreso puede ser profesional o personal, aunque, dado el equilibrio entre trabajo y vida, a menudo se considera la intersección de los dos. El mentor actúa como un asesor de confianza y un modelo a seguir, compartiendo su experiencia y ayudando al mentee a desarrollar sus habilidades, conocimientos y capacidades."
  },
  "Mentoratul este o relație. Este o relație între mentor și mentorat. Mentoratul este definit ca o relație unu-la-unu în care o persoană cu mai multă experiență acordă în mod voluntar timp pentru a preda, sprijini și încuraja pe altul. Conceptul de mentorat se referă la sprijinul emoțional și la îndrumarea oferite de obicei de un individ matur unei persoane mai tinere. Există multă perfecționare a definiției în literatura recentă despre mentorat. Mentoratul transmite cunoștințele despre subiecte, facilitează dezvoltarea personală, încurajează alegerile înțelepte și ajută mentoratul să facă tranziții.": {
    "en": "Mentoring is a relationship. It is a relationship between the mentor and the mentee. Mentoring is defined as a one-to-one relationship in which a more experienced person voluntarily gives time to teach, support, and encourage another. The concept of mentoring relates to emotional support and guidance usually given by a mature individual to a younger person. There is much refining of the definition in the recent literature on mentoring. Mentoring passes on knowledge of subjects, facilitates personal development, encourages wise choices, and helps the mentee to make transitions.",
    "fr": "Le mentorat est une relation. C'est une relation entre un mentor et un mentoré. Le mentorat est défini comme une relation un à un dans laquelle une personne ayant plus d'expérience donne volontairement de son temps pour enseigner, soutenir et encourager une autre personne. Le concept de mentorat fait référence au soutien émotionnel et à l'orientation généralement fournis par un individu mature à une personne plus jeune. Il y a beaucoup de raffinement de la définition dans la littérature récente sur le mentorat. Le mentorat transmet des connaissances sur des sujets, facilite le développement personnel, encourage les choix judicieux et aide le mentoré à effectuer des transitions.",
    "it": "Il mentoring è una relazione. È una relazione tra un mentore e un mentee. Il mentoring è definito come una relazione uno a uno in cui una persona con più esperienza dedica volontariamente del tempo per insegnare, sostenere e incoraggiare un'altra persona. Il concetto di mentoring si riferisce al supporto emotivo e all'orientamento di solito forniti da un individuo maturo a una persona più giovane. C'è molto affinamento della definizione nella letteratura recente sul mentoring. Il mentoring trasmette conoscenze su argomenti, facilita lo sviluppo personale, incoraggia scelte sagge e aiuta il mentee a fare transizioni.",
    "pt": "Mentoria é uma relação. É uma relação entre um mentor e um mentorado. A mentoria é definida como uma relação um-para-um na qual uma pessoa com mais experiência dedica voluntariamente tempo para ensinar, apoiar e encorajar outra pessoa. O conceito de mentoria refere-se ao apoio emocional e orientação geralmente fornecidos por um indivíduo maduro a uma pessoa mais jovem. Há muito refinamento da definição na literatura recente sobre mentoria. A mentoria transmite conhecimento sobre assuntos, facilita o desenvolvimento pessoal, encoraja escolhas sábias e ajuda o mentorado a fazer transições.",
    "el": "Το μέντορας είναι μια σχέση. Είναι μια σχέση μεταξύ ενός μέντορα και ενός μέντορα. Το μέντορας ορίζεται ως μια μια-προς-μια σχέση στην οποία κάποιος με περισσότερη εμπειρία δίνει εθελοντικά χρόνο για να διδάξει, να υποστηρίξει και να ενθαρρύνει κάποιον άλλο. Το έννοια του μέντορα αναφέρεται στην συναισθηματική υποστήριξη και καθοδήγηση που συνήθως παρέχεται από έναν ώριμο άτομο σε ένα νεότερο άτομο. Υπάρχει πολλή τελειοποίηση του ορισμού στην πρόσφατη λογοτεχνία για το μέντορα. Ο μέντορας μεταδίδει γνώσεις για θέματα, διευκολύνει την προσωπική ανάπτυξη, ενθαρρύνει σοφές επιλογές και βοηθά τον μέντορα να κάνει μεταβάσεις.",
    "nl": "Mentoring is een relatie. Het is een relatie tussen een mentor en een mentee. Mentoring wordt gedefinieerd als een één-op-één relatie waarin een persoon met meer ervaring vrijwillig tijd besteedt om een ander te leren, te ondersteunen en aan te moedigen. Het concept van mentoring verwijst naar de emotionele steun en begeleiding die meestal wordt geboden door een volwassen individu aan een jonger persoon. Er is veel verfijning van de definitie in recente literatuur over mentoring. Mentoring brengt kennis over onderwerpen over, vergemakkelijkt persoonlijke ontwikkeling, moedigt wijze keuzes aan en helpt de mentee bij overgangen.",
    "de": "Mentoring ist eine Beziehung. Es ist eine Beziehung zwischen dem Mentor und dem Mentee. Mentoring wird als eine Eins-zu-eins-Beziehung definiert, in der eine erfahrener Person freiwillig Zeit investiert, um eine andere Person zu lehren, zu unterstützen und zu ermutigen. Das Mentoring-Konzept bezieht sich auf emotionale Unterstützung und Anleitung, die in der Regel von einer reifen Person an eine jüngere Person gegeben wird. Die Definition wurde in der jüngsten Literatur zum Mentoring weiter verfeinert. Mentoring gibt Wissen über Themen weiter, fördert die persönliche Entwicklung, ermutigt zu klugen Entscheidungen und hilft dem Mentee, Übergänge zu meistern.",
    "es": "El mentoring es una relación. Es una relación entre el mentor y el mentee. El mentoring se define como una relación uno a uno en la que una persona con más experiencia dedica tiempo de forma voluntaria para enseñar, apoyar y alentar a otra. El concepto de mentoring se refiere al apoyo emocional y la orientación generalmente brindados por una persona madura a una persona más joven. La definición ha sido refinada en la literatura reciente sobre el mentoring. El mentoring transmite conocimientos sobre diversos temas, facilita el desarrollo personal, fomenta elecciones sabias y ayuda al mentee a hacer transiciones."
  },
  "1. Cine este un Copil?": {
    "en": "1. Who is a Child?",
    "fr": "1. Qui est un enfant?",
    "it": "1. Chi è un bambino?",
    "pt": "1. Quem é uma criança?",
    "el": "1. Ποιος είναι ένα παιδί;",
    "nl": "1. Wie is een kind?",
    "de": "1. Wer ist ein Kind?",
    "es": "1. ¿Quién es un niño?"
  },
  "Conform Convenției cu privire la Drepturile Copilului (1989), considerăm orice persoană sub vârsta de 18 ani un copil. Acest lucru înseamnă că au anumite drepturi specifice alături de cele de care se bucură ceilalți membri ai societății, deoarece se află în proces de dezvoltare.": {
    "en": "As described in Article 1 of the Convention on the Rights of the Child (1989),  we consider anyone under the age of 18 a child. This means they have some specific rights alongside those enjoyed by other members of the society as they are in the process of development.",
    "fr": "Comme décrit à l'article 1 de la Convention relative aux droits de l'enfant (1989), nous considérons toute personne de moins de 18 ans comme un enfant. Cela signifie qu'ils ont certains droits spécifiques aux côtés de ceux dont jouissent les autres membres de la société, car ils sont en cours de développement.",
    "it": "Come descritto all'articolo 1 della Convenzione sui diritti del fanciullo (1989), consideriamo chiunque abbia meno di 18 anni un bambino. Ciò significa che hanno alcuni diritti specifici insieme a quelli goduti dagli altri membri della società poiché sono in fase di sviluppo.",
    "pt": "Conforme descrito no Artigo 1 da Convenção sobre os Direitos da Criança (1989), consideramos qualquer pessoa com menos de 18 anos uma criança. Isso significa que eles têm alguns direitos específicos ao lado dos desfrutados por outros membros da sociedade, pois estão em processo de desenvolvimento.",
    "el": "Όπως περιγράφεται στο Άρθρο 1 της Σύμβασης για τα Δικαιώματα του Παιδιού (1989), θεωρούμε κάθε άτομο κάτω των 18 ετών ένα παιδί. Αυτό σημαίνει ότι έχουν κάποια συγκεκριμένα δικαιώματα δίπλα σε αυτά που απολαμβάνουν τα άλλα μέλη της κοινωνίας καθώς βρίσκονται σε διαδικασία ανάπτυξης.",
    "nl": "Zoals beschreven in Artikel 1 van het Verdrag inzake de Rechten van het Kind (1989), beschouwen wij iedereen onder de 18 jaar als een kind. Dit betekent dat zij bepaalde specifieke rechten hebben naast die welke genoten worden door andere leden van de samenleving, omdat zij zich in een ontwikkelingsproces bevinden.",
    "de": "Wie in Artikel 1 des Übereinkommens über die Rechte des Kindes (1989) beschrieben, betrachten wir jede Person unter 18 Jahren als Kind. Das bedeutet, dass sie spezifische Rechte haben, zusätzlich zu denen, die andere Mitglieder der Gesellschaft genießen, da sie sich im Entwicklungsprozess befinden.",
    "es": "Como se describe en el Artículo 1 de la Convención sobre los Derechos del Niño (1989), consideramos niño a cualquier persona menor de 18 años. Esto significa que tienen algunos derechos específicos junto con aquellos disfrutados por otros miembros de la sociedad, ya que están en proceso de desarrollo."
  },
  "Adulții trebuie să respecte maturitatea și capacitatea lor de a negocia situații care variază în funcție de faza de creștere. Aceștia ar trebui să beneficieze de un mediu sigur și protector în care să primească îngrijirea necesară pentru a se dezvolta fizic, cognitiv și emoțional. Neglijarea acestor nevoi este înțeleasă ca o formă de violență împotriva copiilor.": {
    "en": "Adults must respect their maturity and capacity to negotiate situations which vary depending on the phase of growth. They should be provided with a safe and protective environment in which they receive the care needed to develop physically, cognitively, and emotionally. Neglecting these needs is understood as a form of violence against children.",
    "fr": "Les adultes doivent respecter leur maturité et leur capacité à négocier des situations qui varient en fonction de la phase de croissance. Ils doivent bénéficier d'un environnement sûr et protecteur dans lequel ils reçoivent les soins nécessaires pour se développer physiquement, cognitivement et émotionnellement. Négliger ces besoins est compris comme une forme de violence contre les enfants.",
    "it": "Gli adulti devono rispettare la loro maturità e capacità di negoziare situazioni che variano a seconda della fase di crescita. Dovrebbero essere forniti di un ambiente sicuro e protettivo in cui ricevono le cure necessarie per svilupparsi fisicamente, cognitivamente ed emotivamente. Trascurare questi bisogni è inteso come una forma di violenza contro i bambini.",
    "pt": "Os adultos devem respeitar a sua maturidade e capacidade de negociar situações que variam dependendo da fase de crescimento. Eles devem ser providos de um ambiente seguro e protetor no qual recebam os cuidados necessários para se desenvolverem fisicamente, cognitivamente e emocionalmente. Negligenciar essas necessidades é entendido como uma forma de violência contra as crianças.",
    "el": "Οι ενήλικες πρέπει να σεβαστούν την ωριμότητά τους και την ικανότητά τους να διαπραγματευτούν καταστάσεις που ποικίλλουν ανάλογα με τη φάση της ανάπτυξης. Θα πρέπει να παρέχονται σε ένα ασφαλές και προστατευτικό περιβάλλον στο οποίο να λαμβάνουν τη φροντίδα που χρειάζονται για να αναπτυχθούν σωματικά, γνωστικά και συναισθηματικά. Η αμέλεια αυτών των αναγκών εννοείται ως μια μορφή βίας κατά των παιδιών.",
    "nl": "Volwassenen moeten hun volwassenheid en vermogen om situaties te onderhandelen die variëren afhankelijk van de groeifase respecteren. Ze moeten worden voorzien van een veilige en beschermende omgeving waarin ze de zorg krijgen die nodig is om zich fysiek, cognitief en emotioneel te ontwikkelen. Het verwaarlozen van deze behoeften wordt begrepen als een vorm van geweld tegen kinderen.",
    "de": "Erwachsene müssen die Reife und die Fähigkeit von Kindern respektieren, mit Situationen umzugehen, die je nach Wachstumsphase variieren. Sie sollten in einem sicheren und schützenden Umfeld aufwachsen, in dem sie die notwendige Fürsorge erhalten, um sich körperlich, kognitiv und emotional zu entwickeln. Die Vernachlässigung dieser Bedürfnisse wird als eine Form von Gewalt gegen Kinder angesehen.",
    "es": "Los adultos deben respetar su madurez y capacidad para negociar situaciones que varían según la fase de crecimiento. Se les debe proporcionar un entorno seguro y protector en el que reciban el cuidado necesario para desarrollarse física, cognitiva y emocionalmente. Negar estas necesidades se entiende como una forma de violencia contra los niños."
  },
  "2. Ce este Abuzul Asupra Copiilor?": {
    "en": "2. What is Child Abuse?",
    "fr": "2. Qu'est-ce que la maltraitance des enfants?",
    "it": "2. Cos'è il maltrattamento dei bambini?",
    "pt": "2. O que é Abuso Infantil?",
    "el": "2. Τι είναι η κακοποίηση των παιδιών;",
    "nl": "2. Wat is Kindermishandeling?",
    "de": "2. Was ist Kindesmissbrauch?",
    "es": "2. ¿Qué es el maltrato infantil?"
  },
  "Abuzul asupra copiilor este orice formă de abuz fizic, emoțional sau sexual și include exploatarea copiilor, comercială sau nu. Abandonul și neglijarea unui copil care rezultă în daune reale sau potențiale împotriva unui copil pot fi, de asemenea, considerate forme de abuz. Abuzul poate fi deliberat sau rezultatul lipsei de acțiune preventivă și de protecție. Noi, ca Parteneriat Mentor+, condamnăm ferm toate formele de abuz asupra copiilor și exploatarea copiilor. Pentru a proteja copiii în toate activitățile noastre și pentru a le garanta respectul și protecția fizică și emoțională, se așteaptă ca mentorii să raporteze orice caz de abuz asupra copiilor coordonatorului direct.": {
    "en": "Child abuse is any form of physical, emotional or sexual abuse and includes exploitation of children, commercial or otherwise. Abandonment and neglect of a child which results in actual or potential damage against a child can also be considered an abuse. Abuse may be deliberate, or a result of a lack of preventative action and protection. We, as Mentor+ Partnership strongly condemn all forms of child abuse and exploitation of children.  In order to protect our children in all our activities, and to guarantee their respect and physical and emotional protection, the mentors are expected report any instance of child abuse to the coordinator directly.",
    "fr": "La maltraitance des enfants est toute forme de maltraitance physique, émotionnelle ou sexuelle et inclut l'exploitation des enfants, commerciale ou autre. L'abandon et la négligence d'un enfant qui entraînent des dommages réels ou potentiels contre un enfant peuvent également être considérés comme une maltraitance. La maltraitance peut être délibérée ou le résultat d'un manque d'action préventive et de protection. Nous, en tant que Partenariat Mentor+, condamnons fermement toutes les formes de maltraitance des enfants et l'exploitation des enfants. Afin de protéger nos enfants dans toutes nos activités et de garantir leur respect et leur protection physique et émotionnelle, il est attendu que les mentors signalent tout cas de maltraitance des enfants au coordinateur direct.",
    "it": "Il maltrattamento dei bambini è qualsiasi forma di abuso fisico, emotivo o sessuale e include lo sfruttamento dei bambini, commerciale o meno. L'abbandono e la negligenza di un bambino che comportano danni reali o potenziali contro un bambino possono anche essere considerati un abuso. Il maltrattamento può essere deliberato o il risultato di una mancanza di azione preventiva e di protezione. Noi, come Partner Mentor+, condanniamo fermamente tutte le forme di abuso sui bambini e lo sfruttamento dei bambini. Al fine di proteggere i nostri bambini in tutte le nostre attività e garantire il loro rispetto e la loro protezione fisica ed emotiva, ci si aspetta che i mentori segnalino direttamente al coordinatore qualsiasi caso di abuso sui bambini.",
    "pt": "O abuso infantil é qualquer forma de abuso físico, emocional ou sexual e inclui a exploração de crianças, comercial ou não. O abandono e a negligência de uma criança que resultam em danos reais ou potenciais contra uma criança também podem ser considerados abuso. O abuso pode ser deliberado ou o resultado de uma falta de ação preventiva e de proteção. Nós, como Parceria Mentor+, condenamos veementemente todas as formas de abuso infantil e exploração de crianças. Para proteger nossas crianças em todas as nossas atividades e garantir seu respeito e proteção física e emocional, espera-se que os mentores relatem diretamente ao coordenador qualquer instância de abuso infantil.",
    "el": "Η κακοποίηση των παιδιών είναι οποιαδήποτε μορφή σωματικής, συναισθηματικής ή σεξουαλικής κακοποίησης και περιλαμβάνει την εκμετάλλευση των παιδιών, εμπορική ή μη. Η εγκατάλειψη και η παραμέληση ενός παιδιού που οδηγεί σε πραγματικές ή δυνητικές ζημίες κατά του παιδιού μπορεί επίσης να θεωρηθεί κακοποίηση. Η κακοποίηση μπορεί να είναι εσκεμμένη ή αποτέλεσμα έλλειψης προληπτικής δράσης και προστασίας. Εμείς, ως Εταιρική Σχέση Mentor+, καταδικάζουμε απερίφραστα όλες τις μορφές κακοποίησης των παιδιών και την εκμετάλλευση των παιδιών. Για να προστατεύσουμε τα παιδιά μας σε όλες τις δραστηριότητές μας και να εγγυηθούμε τον σεβασμό και την φυσική και συναισθηματική προστασία τους, αναμένεται από τους μέντορες να αναφέρουν άμεσα στον συντονιστή οποιαδήποτε περίπτωση κακοποίησης παιδιών.",
    "nl": "Kindermishandeling is elke vorm van fysiek, emotioneel of seksueel misbruik en omvat de exploitatie van kinderen, commercieel of anderszins. Verlating en verwaarlozing van een kind die leiden tot daadwerkelijke of potentiële schade tegen een kind kunnen ook worden beschouwd als misbruik. Misbruik kan opzettelijk zijn, of het gevolg zijn van een gebrek aan preventieve actie en bescherming. Wij, als Mentor+ Partnership, veroordelen krachtig alle vormen van kindermishandeling en exploitatie van kinderen. Om onze kinderen te beschermen in al onze activiteiten en hun respect en fysieke en emotionele bescherming te garanderen, wordt van de mentoren verwacht dat zij elk geval van kindermishandeling rechtstreeks aan de coördinator melden.",
    "de": "Kindesmissbrauch ist jede Form von körperlichem, emotionalem oder sexuellem Missbrauch und umfasst die Ausbeutung von Kindern, kommerziell oder anders. Die Vernachlässigung eines Kindes, die zu tatsächlichen oder potenziellen Schäden gegen das Kind führt, kann ebenfalls als Missbrauch angesehen werden. Missbrauch kann absichtlich oder das Ergebnis eines Mangels an Präventionsmaßnahmen und Schutz sein. Wir als Mentor+ Partnerschaft verurteilen jegliche Form von Kindesmissbrauch und -ausbeutung aufs Schärfste. Um unsere Kinder bei allen Aktivitäten zu schützen und ihren Respekt sowie ihre körperliche und emotionale Sicherheit zu gewährleisten, wird von den Mentoren erwartet, jeden Fall von Kindesmissbrauch direkt dem Koordinator zu melden.",
    "es": "El maltrato infantil es cualquier forma de abuso físico, emocional o sexual e incluye la explotación de niños, ya sea comercial o de otro tipo. El abandono y la negligencia de un niño que resultan en daños reales o potenciales contra un niño también pueden considerarse abuso. El maltrato puede ser deliberado o el resultado de la falta de acción preventiva y protección. Nosotros, como Mentor+ Partnership, condenamos enérgicamente todas las formas de maltrato y explotación infantil. Para proteger a nuestros niños en todas nuestras actividades y garantizar su respeto y protección física y emocional, se espera que los mentores informen directamente al coordinador sobre cualquier caso de maltrato infantil."
  },
  "3. Codul de conduită pentru mentori": {
    "en": "3. Code of Conduct for Mentors",
    "fr": "3. Code de conduite pour les mentors",
    "it": "3. Codice di condotta per i mentori",
    "pt": "3. Código de Conduta para Mentores",
    "el": "3. Κώδικας Δεοντολογίας για Μέντορες",
    "nl": "3. Gedragscode voor Mentoren",
    "de": "3. Verhaltenskodex für Mentoren",
    "es": "3. Código de Conducta para Mentores"
  },
  "Partenerii Mentor+ apreciază foarte mult principiul conform căruia copiii și tinerii pot beneficia nemăsurat de relațiile de sprijin cu adulții care îi pot ghida și ajuta. Pentru ca acest principiu să funcționeze fără probleme, parteneriatul consideră codul de conduită prezentat mai jos extrem de important. Acestea ar trebui să fie digerate cu faptul că, în special, comportamentul mentorilor poate fi supus controlului și că se așteaptă ca aceștia să fie modele de bune practici în ceea ce privește lucrul cu copiii și tinerii și să nu se implice în niciunul dintre următoarele comportamente:": {
    "en": "Mentor+ Partners highly esteem the principle that children and young people can benefit immeasurably from supportive relationships with adults who can guide and help them. To make this principle work seamlessly, the partnership considers the code of conduct outlined here below extremely important. Those should be digested with the fact that especially the mentors’ behaviour may be open to scrutiny and that they are expected to be models of best practice in relation to working with children and young people and not engage in any of the following behaviours:",
    "fr": "Les partenaires Mentor+ estiment grandement que les enfants et les jeunes peuvent bénéficier énormément de relations de soutien avec des adultes qui peuvent les guider et les aider. Pour que ce principe fonctionne de manière transparente, le partenariat considère le code de conduite présenté ci-dessous extrêmement important. Ceux-ci devraient être digérés avec le fait que surtout le comportement des mentors peut être soumis à un examen minutieux et qu'ils sont censés être des modèles de bonnes pratiques en matière de travail avec les enfants et les jeunes et ne pas s'engager dans l'un des comportements suivants :",
    "it": "I partner di Mentor+ stimano molto il principio secondo cui i bambini e i giovani possono beneficiare immensamente di relazioni di sostegno con adulti che possono guidarli e aiutarli. Per far funzionare questo principio in modo impeccabile, il partenariato considera il codice di condotta qui di seguito estremamente importante. Questi dovrebbero essere digeriti con il fatto che soprattutto il comportamento dei mentori può essere sottoposto a scrutinio e che ci si aspetta che siano modelli di best practice in relazione al lavoro con i bambini e i giovani e non impegnarsi in nessuno dei seguenti comportamenti:",
    "pt": "Os parceiros Mentor+ estimam muito o princípio de que as crianças e os jovens podem beneficiar imensamente de relações de apoio com adultos que podem orientá-los e ajudá-los. Para que este princípio funcione sem problemas, a parceria considera o código de conduta aqui delineado extremamente importante. Estes devem ser digeridos com o fato de que especialmente o comportamento dos mentores pode estar sujeito a escrutínio e que se espera que sejam modelos de melhores práticas em relação ao trabalho com crianças e jovens e não se envolvam em nenhum dos seguintes comportamentos:",
    "el": "Οι εταίροι του Mentor+ εκτιμούν υψηλά την αρχή ότι τα παιδιά και οι νέοι μπορούν να επωφεληθούν απεριόριστα από υποστηρικτικές σχέσεις με ενήλικες που μπορούν να τους καθοδηγήσουν και να τους βοηθήσουν. Για να λειτουργήσει αυτή η αρχή άψογα, η εταιρική σχέση θεωρεί τον κώδικα δεοντολογίας που παρουσιάζεται παρακάτω υψίστης σημασίας. Αυτά πρέπει να πέσουν στο κενό με το γεγονός ότι ειδικά τη συμπεριφορά των μεντόρων μπορεί να υπόκειται σε ελεγχο και αναμένεται να είναι μοντέλα καλών πρακτικών σχετικά με την εργασία με παιδιά και νέους και να μην εμπλέκονται σε κανένα από τα ακόλουθα συμπεριφορικά πρότυπα:",
    "nl": "Mentor+ Partners schatten het principe dat kinderen en jongeren enorm kunnen profiteren van ondersteunende relaties met volwassenen die hen kunnen begeleiden en helpen, zeer hoog in. Om dit principe naadloos te laten werken, beschouwt de samenwerking de hieronder beschreven gedragscode als uiterst belangrijk. Deze moeten worden verwerkt met het feit dat vooral het gedrag van de mentoren kan worden onderworpen aan controle en dat van hen wordt verwacht dat zij modellen zijn van best practices met betrekking tot het werken met kinderen en jongeren en zich niet bezighouden met een van de volgende gedragingen:",
    "de": "Die Mentor+ Partner schätzen das Prinzip hoch ein, dass Kinder und Jugendliche immens von unterstützenden Beziehungen zu Erwachsenen profitieren können, die sie leiten und unterstützen können. Um dieses Prinzip nahtlos umzusetzen, hält die Partnerschaft den unten dargestellten Verhaltenskodex für äußerst wichtig. Dieser muss mit der Tatsache in Einklang gebracht werden, dass das Verhalten der Mentoren insbesondere der Prüfung unterliegen kann und sie als Vorbilder für Best Practices bei der Arbeit mit Kindern und Jugendlichen gelten sollen und sich nicht an den folgenden Verhaltensweisen beteiligen dürfen:",
    "es": "Los socios de Mentor+ valoran enormemente el principio de que los niños y jóvenes pueden beneficiarse inmensamente de las relaciones de apoyo con adultos que pueden guiarlos y ayudarlos. Para que este principio funcione sin problemas, la asociación considera extremadamente importante el código de conducta que se describe a continuación. Esto debe tenerse en cuenta con el hecho de que, especialmente, el comportamiento de los mentores puede estar sujeto a escrutinio y se espera que sean modelos de buenas prácticas en relación con el trabajo con niños y jóvenes y que no se involucren en ninguno de los siguientes comportamientos:"
  },
  "Hărțuirea, hărțuirea sau discriminarea oricărui copil (chiar și în moduri subtile, cum ar fi atragerea atenției asupra diferențelor personale sau fizice).": {
    "en": "Bullying, harassment, or discrimination against any child (even in subtle ways such as drawing attention to personal or physical differences).",
    "fr": "Intimidation, harcèlement ou discrimination contre tout enfant (même de manière subtile, comme attirer l'attention sur des différences personnelles ou physiques).",
    "it": "Bullismo, molestie o discriminazione contro qualsiasi bambino (anche in modi sottili come attirare l'attenzione sulle differenze personali o fisiche).",
    "pt": "Bullying, assédio ou discriminação contra qualquer criança (mesmo de maneiras sutis, como chamar a atenção para diferenças pessoais ou físicas).",
    "el": "Εκφοβισμός, παρενόχληση ή διάκριση κατά οποιουδήποτε παιδιού (ακόμη και με διακριτικούς τρόπους όπως να επισημαίνονται προσωπικές ή σωματικές διαφορές).",
    "nl": "Pesten, intimidatie of discriminatie tegen elk kind ( zelfs op subtiele manieren zoals aandacht vestigen op persoonlijke of fysieke verschillen).",
    "de": "Mobbing, Belästigung oder Diskriminierung eines Kindes (auch auf subtile Weise, indem man auf persönliche oder körperliche Unterschiede hinweist).",
    "es": "Acoso, hostigamiento o discriminación contra cualquier niño (incluso de maneras sutiles, como llamar la atención sobre diferencias personales o físicas)."
  },
  "Înjosirea sau rușinerea unui copil care se luptă sau căruia îi este dificilă munca.": {
    "en": "Belittling or shaming a child who is struggling or who finds the work difficult.",
    "fr": "Rabaisser ou humilier un enfant qui a des difficultés ou qui trouve le travail difficile.",
    "it": "Deridere o umiliare un bambino che sta lottando o che trova difficile il lavoro.",
    "pt": "Menosprezar ou envergonhar uma criança que está lutando ou que acha o trabalho difícil.",
    "el": "Μειώνοντας ή ντροπιάζοντας ένα παιδί που δυσκολεύεται ή που βρίσκει τη δουλειά δύσκολη.",
    "nl": "Een kind dat worstelt of het werk moeilijk vindt kleineren of beschaamd maken.",
    "de": "Ein Kind, das Schwierigkeiten hat oder die Arbeit schwierig findet, herabsetzen oder beschämen.",
    "es": "Menospreciar o avergonzar a un niño que está luchando o que encuentra difícil el trabajo."
  },
  "Lovirea, plesnirea sau cauzarea de vătămări fizice unui copil ca o ieșire pentru frustrare sau ca mijloc de disciplină.": {
    "en": "Hitting, smacking, or causing physical harm to a child as an outlet for frustration or as a means of discipline.",
    "fr": "Frapper, gifler ou causer des dommages physiques à un enfant comme exutoire de la frustration ou comme moyen de discipline.",
    "it": "Colpire, schiaffeggiare o causare danni fisici a un bambino come sfogo per la frustrazione o come mezzo di disciplina.",
    "pt": "Bater, dar um tapa ou causar danos físicos a uma criança como uma saída para a frustração ou como meio de disciplina.",
    "el": "Χτύπημα, χαστούκι ή πρόκληση σωματικής βλάβης σε ένα παιδί ως διέξοδος για την απογοήτευση ή ως μέσο πειθαρχίας.",
    "nl": "Slaan, een klap geven of fysieke schade toebrengen aan een kind als uitlaatklep voor frustratie of als middel van discipline.",
    "de": "Schlagen, Ohrfeigen oder körperliches Leid zufügen, um Frust abzulassen oder als Disziplinarmaßnahme.",
    "es": "Golpear, abofetear o causar daño físico a un niño como una salida para la frustración o como medio de disciplina."
  },
  "Interacționând cu copiii într-un mod nepotrivit, evidențiind-i pentru laudă sau atenție sau încercând să-i umilești sau să-i facă să se simtă inconfortabil.": {
    "en": "Interacting with children in an inappropriate way, singling them out for praise or attention or trying to humiliate them or make them feel uncomfortable.",
    "fr": "Interagir avec les enfants de manière inappropriée, en les isolant pour les éloges ou l'attention ou en essayant de les humilier ou de les rendre mal à l'aise.",
    "it": "Interagire con i bambini in modo inappropriato, singolandoli per lodi o attenzione o cercando di umiliarli o farli sentire a disagio.",
    "pt": "Interagindo com crianças de maneira inadequada, destacando-as para elogios ou atenção ou tentando humilhá-las ou fazê-las sentir-se desconfortáveis.",
    "el": "Αλληλεπιδρώντας με τα παιδιά με ακατάλληλο τρόπο, ξεχωρίζοντας τα για επαίνους ή προσοχή ή προσπαθώντας να ταπεινώσετε ή να τα κάνετε να νιώσουν άβολα.",
    "nl": "Op een ongepaste manier met kinderen omgaan, hen afzonderen voor lof of aandacht of proberen hen te vernederen of zich ongemakkelijk te laten voelen.",
    "de": "In unangemessener Weise mit Kindern interagieren, sie für Lob oder Aufmerksamkeit herausheben oder versuchen, sie zu demütigen oder ihnen ein unangenehmes Gefühl zu geben.",
    "es": "Interactuar con los niños de manera inapropiada, destacándolos para elogios o atención o tratando de humillarlos o hacer que se sientan incómodos."
  },
  "Intrarea într-o relație intimă sau sexuală cu un copil sau utilizarea limbajului sexual în jurul acestuia, inclusiv comentarii sau conversații sugestive.": {
    "en": "Entering an intimate or sexual relationship with a child or using sexual language around them including suggestive comments or conversations.",
    "fr": "Entrer dans une relation intime ou sexuelle avec un enfant ou utiliser un langage sexuel autour d'eux, y compris des commentaires ou des conversations suggestives.",
    "it": "Entrare in una relazione intima o sessuale con un bambino o usare un linguaggio sessuale intorno a loro, inclusi commenti o conversazioni suggestive.",
    "pt": "Entrar em um relacionamento íntimo ou sexual com uma criança ou usar linguagem sexual ao seu redor, incluindo comentários ou conversas sugestivas.",
    "el": "Εισέρχεστε σε μια στενή ή σεξουαλική σχέση με ένα παιδί ή χρησιμοποιώντας σεξουαλική γλώσσα γύρω τους, συμπεριλαμβανομένων υπονοητικών σχολίων ή συνομιλιών.",
    "nl": "Een intieme of seksuele relatie aangaan met een kind of seksuele taal gebruiken in hun buurt, inclusief suggestieve opmerkingen of gesprekken.",
    "de": "Eine intime oder sexuelle Beziehung mit einem Kind eingehen oder sexuelle Sprache um sie herum verwenden, einschließlich anzüglicher Kommentare oder Gespräche.",
    "es": "Iniciar una relación íntima o sexual con un niño o usar lenguaje sexual a su alrededor, incluidos comentarios o conversaciones sugestivas."
  },
  "Intrarea într-o relație cu un mentorat în afara proiectului.": {
    "en": "Entering a relationship with a mentee outside of the project.",
    "fr": "Entrer dans une relation avec un mentoré en dehors du projet.",
    "it": "Entrare in una relazione con un mentee al di fuori del progetto.",
    "pt": "Entrar em um relacionamento com um mentorado fora do projeto.",
    "el": "Είσοδος σε σχέση με έναν μέντορα εκτός του έργου.",
    "nl": "Een relatie aangaan met een mentee buiten het project.",
    "de": "Eine Beziehung mit einem Mentee außerhalb des Projekts eingehen.",
    "es": "Iniciar una relación con un mentee fuera del proyecto."
  },
  "Interacțiunea individuală cu mentorați pe rețelele sociale, cum ar fi WhatsApp, Snapchat, Instagram sau alt serviciu de rețele sociale, serviciu de mesagerie sau joc. Acceptarea invitațiilor prietenilor sau împărtășirea numerelor personale cu oricare dintre copiii din proiect.": {
    "en": "Engaging one-to-one with the mentees on social media, such as WhatsApp, Snapchat, Instagram or another social media service, messaging service or game. Accepting friend invitations or sharing personal numbers with any of the children in the project.",
    "fr": "Engager une interaction individuelle avec les mentorés sur les réseaux sociaux, tels que WhatsApp, Snapchat, Instagram ou un autre service de réseau social, service de messagerie ou jeu. Accepter les invitations d'amis ou partager des numéros personnels avec n'importe lequel des enfants du projet.",
    "it": "Impegnarsi uno-a-uno con i mentee sui social media, come WhatsApp, Snapchat, Instagram o un altro servizio di social media, servizio di messaggistica o gioco. Accettare inviti di amici o condividere numeri personali con qualsiasi bambino del progetto.",
    "pt": "Envolver-se um a um com os mentorados nas redes sociais, como WhatsApp, Snapchat, Instagram ou outro serviço de mídia social, serviço de mensagens ou jogo. Aceitar convites de amizade ou compartilhar números pessoais com qualquer uma das crianças do projeto.",
    "el": "Αλληλεπιδρώντας ένας προς έναν με τους μέντορες στα μέσα κοινωνικής δικτύωσης, όπως το WhatsApp, το Snapchat, το Instagram ή άλλη υπηρεσία κοινωνικών μέσων, υπηρεσία ανταλλαγής μηνυμάτων ή παιχνίδι. Αποδοχή προσκλήσεων φίλων ή κοινή χρήση προσωπικών αριθμών με οποιοδήποτε από τα παιδιά στο έργο.",
    "nl": "Een-op-een communiceren met de mentees op sociale media, zoals WhatsApp, Snapchat, Instagram of een andere sociale mediaservice, berichtendienst of spel. Vriendschapsverzoeken accepteren of persoonlijke nummers delen met een van de kinderen in het project.",
    "de": "Eins-zu-eins-Interaktion mit den Mentees über soziale Netzwerke wie WhatsApp, Snapchat, Instagram oder andere soziale Netzwerke, Messaging-Dienste oder Spiele. Freundschaftseinladungen annehmen oder persönliche Telefonnummern mit einem der Kinder im Projekt teilen.",
    "es": "Interactuar uno a uno con los mentees en redes sociales, como WhatsApp, Snapchat, Instagram u otro servicio de redes sociales, servicio de mensajería o juegos. Aceptar invitaciones de amistad o compartir números personales con cualquiera de los niños en el proyecto."
  },
  "Faceți fotografii copiilor sau distribuiți fotografii cu dvs.": {
    "en": "Taking pictures of the children or sharing photos of yourself.",
    "fr": "Prendre des photos des enfants ou partager des photos de vous-même.",
    "it": "Fare foto ai bambini o condividere foto di te stesso.",
    "pt": "Tirar fotos das crianças ou compartilhar fotos de si mesmo.",
    "el": "Φωτογραφίζοντας τα παιδιά ή μοιράζοντας φωτογραφίες του εαυτού σας.",
    "nl": "Foto's maken van de kinderen of foto's van jezelf delen.",
    "de": "Fotos von den Kindern machen oder Fotos von sich selbst teilen.",
    "es": "Tomar fotos de los niños o compartir fotos de ti mismo."
  },
  "Oferirea de cadouri, privilegii sau recompense unui copil pentru a construi o relație specială cu el. Asumarea sarcinilor de mentorat în timp ce se află sub influența alcoolului sau a drogurilor.": {
    "en": "Giving gifts, privileges, or rewards to a child to build a special relationship with them. Undertaking mentoring duties whilst under the influence of alcohol or drugs.",
    "fr": "Offrir des cadeaux, des privilèges ou des récompenses à un enfant pour construire une relation spéciale avec lui. Assumer des tâches de mentorat sous l'influence de l'alcool ou de drogues.",
    "it": "Dare regali, privilegi o ricompense a un bambino per costruire una relazione speciale con lui. Svolgere compiti di tutoraggio sotto l'influenza di alcol o droghe.",
    "pt": "Dar presentes, privilégios ou recompensas a uma criança para construir um relacionamento especial com ela. Realizar tarefas de mentoria sob a influência de álcool ou drogas.",
    "el": "Δίνοντας δώρα, προνόμια ή ανταμοιβές σε ένα παιδί για να χτίσετε μια ειδική σχέση μαζί του. Αναλαμβάνοντας καθήκοντα καθοδήγησης ενώ βρίσκεστε υπό την επήρεια αλκοόλ ή ναρκωτικών.",
    "nl": "Cadeaus, privileges of beloningen geven aan een kind om een speciale relatie met hen op te bouwen. Mentortaken uitvoeren onder invloed van alcohol of drugs.",
    "de": "Geschenke, Privilegien oder Belohnungen einem Kind geben, um eine besondere Beziehung zu ihm aufzubauen. Mentoring-Aufgaben unter dem Einfluss von Alkohol oder Drogen ausüben.",
    "es": "Dar regalos, privilegios o recompensas a un niño para construir una relación especial con él. Asumir tareas de mentoría bajo la influencia de alcohol o drogas."
  },
  "Proiectul Mentor+": {
    "en": "Mentor+ Project",
    "fr": "Projet Mentor+",
    "it": "Progetto Mentor+",
    "pt": "Projeto Mentor+",
    "el": "Έργο Mentor+",
    "nl": "Mentor+ Project",
    "de": "Mentor+ Projekt",
    "es": "Proyecto Mentor+"
  },
  "Proiectul Mentor+ oferă o intervenție de educație non-formală pentru a angaja, conecta și împuternici tinerii aflați în conflict cu legea. Mai precis, proiectul creează un model de mentorat care folosește adulți modele de comportament respectuosi de lege pentru a sprijini tinerii aflați în conflict cu legea în procesul de dezvoltare a unor atitudini și comportamente pozitive. Proiectul își propune să realizeze acest lucru prin trei oferte:": {
    "en": "Mentor+ Project offers an intervention of non-formal education to engage, connect and empower young people in conflict with the law. More specifically, the project creates a mentoring model that employs law-abiding role model adults to support juveniles in conflict with the law in the process of developing positive attitudes and behaviours. The project aims accomplish this by three offers:",
    "fr": "Le projet Mentor+ offre une intervention d'éducation non formelle pour engager, connecter et autonomiser les jeunes en conflit avec la loi. Plus précisément, le projet crée un modèle de mentorat qui emploie des adultes modèles respectueux de la loi pour soutenir les jeunes en conflit avec la loi dans le processus de développement d'attitudes et de comportements positifs. Le projet vise à accomplir cela par trois offres:",
    "it": "Il progetto Mentor+ offre un intervento di educazione non formale per coinvolgere, connettere ed emancipare i giovani in conflitto con la legge. Più specificamente, il progetto crea un modello di mentoring che impiega adulti modello rispettosi della legge per supportare i giovani in conflitto con la legge nel processo di sviluppo di atteggiamenti e comportamenti positivi. Il progetto mira a realizzare questo attraverso tre offerte:",
    "pt": "O Projeto Mentor+ oferece uma intervenção de educação não formal para envolver, conectar e capacitar jovens em conflito com a lei. Mais especificamente, o projeto cria um modelo de mentoria que emprega adultos modelos de comportamento respeitadores da lei para apoiar os jovens em conflito com a lei no processo de desenvolvimento de atitudes e comportamentos positivos. O projeto visa alcançar isso através de três ofertas:",
    "el": "Το έργο Mentor+ προσφέρει μια παρέμβαση μη τυπικής εκπαίδευσης για να εμπλέξει, να συνδέσει και να ενδυναμώσει τους νέους που βρίσκονται σε σύγκρουση με τον νόμο. Πιο συγκεκριμένα, το έργο δημιουργεί ένα μοντέλο καθοδήγησης που χρησιμοποιεί ενήλικες πρότυπα που τηρούν τον νόμο για να υποστηρίξουν τους ανήλικους που βρίσκονται σε σύγκρουση με τον νόμο στη διαδικασία ανάπτυξης θετικών στάσεων και συμπεριφορών. Το έργο στοχεύει να το επιτύχει αυτό μέσω τριών προσφορών:",
    "nl": "Het Mentor+ Project biedt een interventie van niet-formeel onderwijs om jongeren in conflict met de wet te betrekken, verbinden en versterken. Meer specifiek creëert het project een mentoringsmodel dat wetgetrouwe rolmodel volwassenen inzet om jongeren in conflict met de wet te ondersteunen bij het ontwikkelen van positieve attitudes en gedragingen. Het project wil dit bereiken door drie aanbiedingen:",
    "de": "Das Mentor+ Projekt bietet eine nicht-formale Bildungsintervention, um junge Menschen, die in Konflikt mit dem Gesetz stehen, zu engagieren, zu verbinden und zu stärken. Genauer gesagt, schafft das Projekt ein Mentoring-Modell, das gesetzestreue Vorbilder für Erwachsene einsetzt, um junge Menschen im Konflikt mit dem Gesetz dabei zu unterstützen, positive Einstellungen und Verhaltensweisen zu entwickeln. Das Projekt zielt darauf ab, dies durch drei Angebote zu erreichen:",
    "es": "El Proyecto Mentor+ ofrece una intervención de educación no formal para involucrar, conectar y empoderar a los jóvenes en conflicto con la ley. Más específicamente, el proyecto crea un modelo de mentoría que emplea a adultos modelos de conducta respetuosos de la ley para apoyar a los jóvenes en conflicto con la ley en el proceso de desarrollar actitudes y comportamientos positivos. El proyecto tiene como objetivo lograr esto a través de tres ofertas:"
  },
  "Designarea și pilotarea unui program de mentorat pentru tinerii implicați în justiție pentru a evita incarcerarea/instituționalizarea acestora, vizând cel puțin 60 de tineri în conflict cu legea din 6 state membre.": {
    "en": "Designing & piloting a mentoring programme for justice-involved youth in order to avoid their incarceration/ institutionalization, targeting at least 60 young people in conflict with the law in 6 Member States.",
    "fr": "Conception et pilotage d'un programme de mentorat pour les jeunes en conflit avec la justice afin d'éviter leur incarcération/institutionnalisation, visant au moins 60 jeunes en conflit avec la loi dans 6 États membres.",
    "it": "Progettazione e sperimentazione di un programma di mentoring per giovani coinvolti nella giustizia al fine di evitare la loro incarcerazione/istituzionalizzazione, mirando almeno a 60 giovani in conflitto con la legge in 6 Stati membri.",
    "pt": "Design e piloto de um programa de mentoria para jovens envolvidos com a justiça para evitar sua encarceramento/institucionalização, visando pelo menos 60 jovens em conflito com a lei em 6 Estados membros.",
    "el": "Σχεδίαση και πιλοτική εφαρμογή ενός προγράμματος μεντορίας για νέους που εμπλέκονται στη δικαιοσύνη προκειμένου να αποφευχθεί η φυλάκισή τους / θεσμικοποίησή τους, με στόχο τουλάχιστον 60 νέους σε σύγκρουση με το νόμο σε 6 κράτη μέλη.",
    "nl": "Het ontwerpen en piloteren van een mentorprogramma voor jongeren die betrokken zijn bij justitie om hun opsluiting/institutionalisering te voorkomen, gericht op minstens 60 jongeren in conflict met de wet in 6 lidstaten.",
    "de": "Entwicklung und Pilotierung eines Mentoring-Programms für jugendliche Straftäter, um deren Inhaftierung/Institutionalisierung zu vermeiden, mit dem Ziel, mindestens 60 junge Menschen in Konflikt mit dem Gesetz in 6 Mitgliedstaaten zu erreichen.",
    "es": "Diseñar y pilotear un programa de mentoría para jóvenes involucrados en la justicia a fin de evitar su encarcelamiento/institucionalización, con el objetivo de llegar a al menos 60 jóvenes en conflicto con la ley en 6 Estados miembros."
  },
  "Crearea unui ghid pentru mentorii pentru a-i sprijini în mentoratul tinerilor implicați în justiție (oferind ghiduri, șabloane și instrumente utile pentru a fi utilizate în timpul sesiunilor de mentorat).": {
    "en": "Creating a Handbook for the Mentors to support them in mentoring justice-involved Youth (providing guidelines, templates and useful tools to put into motion during the mentoring sessions).",
    "fr": "Création d'un guide pour les mentors pour les soutenir dans le mentorat des jeunes en conflit avec la justice (fournissant des lignes directrices, des modèles et des outils utiles à mettre en œuvre lors des séances de mentorat).",
    "it": "Creazione di un manuale per i mentori per supportarli nel mentoring dei giovani coinvolti nella giustizia (fornendo linee guida, modelli e strumenti utili da utilizzare durante le sessioni di mentoring).",
    "pt": "Criação de um Manual para os Mentores apoiá-los no mentoring de jovens envolvidos com a justiça (fornecendo diretrizes, modelos e ferramentas úteis para serem utilizadas durante as sessões de mentoria).",
    "el": "Δημιουργία ενός εγχειριδίου για τους Μέντορες για να τους υποστηρίξουν στο μεντορινγκ νέων που εμπλέκονται στη δικαιοσύνη (παρέχοντας οδηγίες, πρότυπα και χρήσιμα εργαλεία για να χρησιμοποιηθούν κατά τη διάρκεια των συνεδριών μεντορίας).",
    "nl": "Het maken van een handleiding voor de Mentoren om hen te ondersteunen bij het mentor van jongeren die betrokken zijn bij justitie (het verstrekken van richtlijnen, sjablonen en nuttige tools om tijdens de mentorsessies in beweging te brengen).",
    "de": "Erstellung eines Handbuchs für Mentoren zur Unterstützung bei der Betreuung von Jugendlichen im Konflikt mit dem Gesetz (Bereitstellung von Richtlinien, Vorlagen und nützlichen Werkzeugen, die während der Mentoring-Sitzungen in Bewegung gesetzt werden können).",
    "es": "Creación de un Manual para los Mentores para apoyarlos en la mentoría de jóvenes involucrados en la justicia (proporcionando pautas, plantillas y herramientas útiles para utilizar durante las sesiones de mentoría)."
  },
  "Crearea și pilotarea unei aplicații mobile, disponibilă în 6 limbi europene diferite, pentru a sprijini mentori și mentorii în timp real, dând vizibilitate valorii adăugate a naturii electronice a e-mentoratului ca mecanism care permite relațiilor de mentorat să depășească limitele geografice și temporale.": {
    "en": "Creating and piloting a mobile application, available in 6 different European languages to support mentors and mentees in real time, giving visibility to the added value of the electronic nature of e-mentoring as a mechanism that allows mentoring relationships to transcend geographical and temporal boundaries.",
    "fr": "Création et pilotage d'une application mobile, disponible en 6 langues européennes différentes pour soutenir les mentors et les mentorés en temps réel, donnant ainsi une visibilité à la valeur ajoutée de la nature électronique du e-mentorat en tant que mécanisme permettant aux relations de mentorat de transcender les frontières géographiques et temporelles.",
    "it": "Creazione e sperimentazione di un'applicazione mobile, disponibile in 6 diverse lingue europee per supportare mentori e mentorati in tempo reale, dando visibilità al valore aggiunto della natura elettronica dell'e-mentoring come meccanismo che consente alle relazioni di mentoring di superare i confini geografici e temporali.",
    "pt": "Criação e pilotagem de um aplicativo móvel, disponível em 6 idiomas europeus diferentes para apoiar mentores e mentorados em tempo real, dando visibilidade ao valor agregado da natureza eletrônica do e-mentoring como um mecanismo que permite que as relações de mentoria transcendam fronteiras geográficas e temporais.",
    "el": "Δημιουργία και δοκιμή μιας κινητής εφαρμογής, διαθέσιμη σε 6 διαφορετικές ευρωπαϊκές γλώσσες για να υποστηρίξει τους μέντορες και τους νεούς σε πραγματικό χρόνο, δίνοντας ορατότητα στην προστιθέμενη αξία της ηλεκτρονικής φύσης του e-mentoring ως μηχανισμός που επιτρέπει στις σχέσεις μεντορίας να ξεπεράσουν γεωγραφικά και χρονικά όρια.",
    "nl": "Het ontwikkelen en piloteren van een mobiele applicatie, beschikbaar in 6 verschillende Europese talen om mentoren en mentees in real-time te ondersteunen, waarbij zichtbaarheid wordt gegeven aan de toegevoegde waarde van de elektronische aard van e-mentoring als een mechanisme dat mentorelaties in staat stelt geografische en temporele grenzen te overschrijden.",
    "de": "Entwicklung und Pilotierung einer mobilen Anwendung, die in 6 verschiedenen europäischen Sprachen verfügbar ist, um Mentoren und Mentees in Echtzeit zu unterstützen und die Sichtbarkeit des Mehrwerts der elektronischen Natur des E-Mentoring als Mechanismus zu erhöhen, der es Mentoring-Beziehungen ermöglicht, geografische und zeitliche Grenzen zu überwinden.",
    "es": "Creación y pilotaje de una aplicación móvil, disponible en 6 idiomas europeos diferentes, para apoyar a mentores y mentees en tiempo real, dando visibilidad al valor añadido de la naturaleza electrónica del e-mentoring como un mecanismo que permite que las relaciones de mentoría trasciendan fronteras geográficas y temporales."
  },
  "Tineretul din cadrul Sistemului de Justiție Juvenil": {
    "en": "Youth within the Juvenile Justice System",
    "fr": "Jeunes au sein du système de justice juvénile",
    "it": "Giovani all'interno del sistema di giustizia minorile",
    "pt": "Juventude no Sistema de Justiça Juvenil",
    "el": "Νεολαία μέσα στο σύστημα νεανικής δικαιοσύνης",
    "nl": "Jeugd binnen het Jeugdrechtssysteem",
    "de": "Jugend im Jugendstrafrechtssystem",
    "es": "Juventud dentro del Sistema de Justicia Juvenil"
  },
  "Tineretul din cadrul sistemului de justiție juvenilă (JJS) sunt printre cei mai vulnerabili cetățeni. Cercetările arată că încarcerarea nu răspunde nevoilor lor și adesea rezultă în consecințe negative asupra comportamentului și sănătății mintale (de exemplu, menținerea sau chiar creșterea nivelurilor de implicare în comportamente antisociale și activități criminale) (Lambie & Randell, 2013). Tineretul din JJS continuă să experimenteze încălcări de rutină ale drepturilor lor de bază, inclusiv violență și izolare în centrele de detenție (Souverein et al., 2019). În plus, relația dintre delincvența juvenilă și abandonul școlar, problemele de sănătate mintală, abuzul de substanțe, șomajul, stilul de viață ilegal, un risc mai mare de perpetuare intergenerațională a tiparelor și tulburările internalizate (de exemplu, depresia și anxietatea) este susținută de cercetări.": {
    "en": "Youth within the juvenile justice system (JJS) are among the most vulnerable citizens. Research shows that incarceration does not attend to their needs, and it often results in negative behavioural & mental health consequences (e.g. maintain, or even increase, levels of engagement in anti-social behaviour and criminal activity)(Lambie & Randell, 2013). Youth within the JJS continue to experience routine violations of their basic rights, including violence and isolation within detention centers (Souverein et al., 2019). Moreover, the relationship between juvenile delinquency & school dropout, mental health problems, substance abuse, unemployment, illegal lifestyle, a higher risk of intergenerational perpetuation of patterns and internalizing disorders (e.g. depression and anxiety) is backboned by research.",
    "fr": "Les jeunes au sein du système de justice juvénile (JJS) sont parmi les citoyens les plus vulnérables. Les recherches montrent que l'incarcération ne répond pas à leurs besoins et qu'elle entraîne souvent des conséquences négatives sur le comportement et la santé mentale (par exemple, maintenir ou même augmenter les niveaux d'engagement dans des comportements antisociaux et des activités criminelles) (Lambie & Randell, 2013). Les jeunes au sein du JJS continuent de subir des violations systématiques de leurs droits fondamentaux, y compris la violence et l'isolement dans les centres de détention (Souverein et al., 2019). De plus, la relation entre la délinquance juvénile et le décrochage scolaire, les problèmes de santé mentale, l'abus de substances, le chômage, le mode de vie illégal, un risque plus élevé de perpétuation intergénérationnelle des schémas et les troubles internalisés (par exemple, la dépression et l'anxiété) est étayée par des recherches.",
    "it": "I giovani all'interno del sistema di giustizia minorile (JJS) sono tra i cittadini più vulnerabili. La ricerca mostra che l'incarcerazione non risponde ai loro bisogni e spesso comporta conseguenze negative sul comportamento e sulla salute mentale (ad esempio, mantenere o addirittura aumentare i livelli di coinvolgimento in comportamenti antisociali e attività criminali) (Lambie & Randell, 2013). I giovani all'interno del JJS continuano a subire violazioni sistematiche dei loro diritti fondamentali, inclusa la violenza e l'isolamento nei centri di detenzione (Souverein et al., 2019). Inoltre, la relazione tra delinquenza giovanile e abbandono scolastico, problemi di salute mentale, abuso di sostanze, disoccupazione, stile di vita illegale, un rischio maggiore di perpetuazione intergenerazionale dei modelli e disturbi internalizzati (ad esempio, depressione e ansia) è supportata dalla ricerca.",
    "pt": "Os jovens no sistema de justiça juvenil (JJS) estão entre os cidadãos mais vulneráveis. A pesquisa mostra que a encarceramento não atende às suas necessidades e muitas vezes resulta em consequências negativas para o comportamento e a saúde mental (por exemplo, manter ou até aumentar os níveis de envolvimento em comportamentos antissociais e atividades criminosas) (Lambie & Randell, 2013). Os jovens no JJS continuam a experimentar violações rotineiras de seus direitos básicos, incluindo violência e isolamento dentro dos centros de detenção (Souverein et al., 2019). Além disso, a relação entre delinquência juvenil e abandono escolar, problemas de saúde mental, abuso de substâncias, desemprego, estilo de vida ilegal, um risco maior de perpetuação intergeracional de padrões e distúrbios internalizantes (por exemplo, depressão e ansiedade) é respaldada por pesquisas.",
    "el": "Οι νέοι εντός του συστήματος νεανικής δικαιοσύνης (JJS) είναι από τους πιο ευάλωτους πολίτες. Η έρευνα δείχνει ότι η φυλάκιση δεν ανταποκρίνεται στις ανάγκες τους και συχνά οδηγεί σε αρνητικές συνέπειες στη συμπεριφορά και την ψυχική υγεία (π.χ. διατήρηση ή ακόμη και αύξηση των επιπέδων εμπλοκής σε αντικοινωνική συμπεριφορά και εγκληματική δραστηριότητα) (Lambie & Randell, 2013). Οι νέοι εντός του JJS συνεχίζουν να βιώνουν συστηματικές παραβιάσεις των βασικών τους δικαιωμάτων, συμπεριλαμβανομένης της βίας και της απομόνωσης στα κέντρα κράτησης (Souverein et al., 2019). Επιπλέον, η σχέση μεταξύ νεανικής παραβατικότητας και εγκατάλειψης του σχολείου, προβλημάτων ψυχικής υγείας, κατάχρησης ουσιών, ανεργίας, παράνομου τρόπου ζωής, υψηλότερου κινδύνου διαιώνισης των προτύπων μεταξύ των γενεών και εσωτερικευμένων διαταραχών (π.χ. κατάθλιψη και άγχος) υποστηρίζεται από την έρευνα.",
    "nl": "Jeugd binnen het jeugdrechtsysteem (JJS) behoort tot de meest kwetsbare burgers. Onderzoek toont aan dat opsluiting niet aan hun behoeften voldoet en vaak resulteert in negatieve gedrags- en mentale gezondheidsgevolgen (bijv. het handhaven of zelfs verhogen van de mate van betrokkenheid bij antisociaal gedrag en criminele activiteiten) (Lambie & Randell, 2013). Jeugd binnen het JJS blijft routinematig schendingen van hun basisrechten ervaren, waaronder geweld en isolatie binnen detentiecentra (Souverein et al., 2019). Bovendien wordt de relatie tussen jeugdcriminaliteit en schooluitval, mentale gezondheidsproblemen, middelenmisbruik, werkloosheid, illegale levensstijl, een hoger risico op intergenerationele voortzetting van patronen en internaliserende stoornissen (bijv. depressie en angst) ondersteund door onderzoek.",
    "de": "Jugendliche im Jugendjustizsystem (JJS) gehören zu den verletzlichsten Bürgern. Forschungen zeigen, dass Inhaftierungen ihren Bedürfnissen nicht gerecht werden und oft zu negativen Verhaltens- und psychischen Folgen führen (z.B. Aufrechterhaltung oder sogar Steigerung der Beteiligung an antisozialem Verhalten und kriminellen Aktivitäten) (Lambie & Randell, 2013). Jugendliche im JJS erleben weiterhin routinemäßige Verletzungen ihrer Grundrechte, einschließlich Gewalt und Isolation in den Haftzentren (Souverein et al., 2019). Darüber hinaus zeigt die Forschung eine Beziehung zwischen Jugendkriminalität und Schulabbruch, psychischen Gesundheitsproblemen, Substanzmissbrauch, Arbeitslosigkeit, illegalem Lebensstil, einem höheren Risiko der intergenerationalen Weitergabe von Mustern und internalisierenden Störungen (z.B. Depression und Angst).",
    "es": "Los jóvenes dentro del sistema de justicia juvenil (JJS) están entre los ciudadanos más vulnerables. La investigación muestra que el encarcelamiento no atiende a sus necesidades, y a menudo resulta en consecuencias negativas para la salud mental y el comportamiento (por ejemplo, mantener o incluso aumentar los niveles de participación en comportamientos antisociales y actividades delictivas) (Lambie & Randell, 2013). Los jóvenes dentro del JJS continúan experimentando violaciones rutinarias de sus derechos básicos, incluyendo violencia y aislamiento en los centros de detención (Souverein et al., 2019). Además, la relación entre la delincuencia juvenil y el abandono escolar, problemas de salud mental, abuso de sustancias, desempleo, estilo de vida ilegal, un mayor riesgo de perpetuación intergeneracional de patrones y trastornos internalizantes (por ejemplo, depresión y ansiedad) está respaldada por la investigación."
  },
  "De ce mentoratul ca metodologie?": {
    "en": "Why Mentoring as Methodology?",
    "fr": "Pourquoi le mentorat comme méthodologie?",
    "it": "Perché il mentoring come metodologia?",
    "pt": "Por que o Mentoring como Metodologia?",
    "el": "Γιατί η καθοδήγηση ως μεθοδολογία;",
    "nl": "Waarom mentoring als methodologie?",
    "de": "Warum Mentoring als Methodik?",
    "es": "¿Por qué la Mentoría como Metodología?"
  },
  "Lipsey et al.’s “Improving the Effectiveness of Juvenile Justice Programs” reflectă rezultatele meta-analizelor privind eficacitatea programelor de reabilitare a infractorilor juvenili (2010, p.12). În consecință:": {
    "en": "Lipsey et al.’s “Improving the Effectiveness of Juvenile Justice Programs” reflects results of meta-analyses on effectiveness of programs to rehabilitate juvenile offenders (2010, p.12). Accordingly:",
    "fr": "« Improving the Effectiveness of Juvenile Justice Programs » de Lipsey et al. reflète les résultats des méta-analyses sur l'efficacité des programmes de réhabilitation des délinquants juvéniles (2010, p.12). En conséquence :",
    "it": "« Improving the Effectiveness of Juvenile Justice Programs » di Lipsey et al. riflette i risultati delle meta-analisi sull'efficacia dei programmi per riabilitare i delinquenti minorili (2010, p.12). Di conseguenza:",
    "pt": "« Improving the Effectiveness of Juvenile Justice Programs » de Lipsey et al. reflete os resultados das meta-análises sobre a eficácia dos programas de reabilitação de delinquentes juvenis (2010, p.12). Consequentemente:",
    "el": "Η μελέτη « Improving the Effectiveness of Juvenile Justice Programs » των Lipsey et al. αντικατοπτρίζει τα αποτελέσματα των μετα-αναλύσεων σχετικά με την αποτελεσματικότητα των προγραμμάτων αποκατάστασης των νεαρών παραβατών (2010, σελ.12). Κατά συνέπεια:",
    "nl": "Lipsey et al.'s « Improving the Effectiveness of Juvenile Justice Programs » weerspiegelt de resultaten van meta-analyses over de effectiviteit van programma's om jeugdige delinquenten te rehabiliteren (2010, p.12). Dienovereenkomstig:",
    "de": "Lipsey et al.s „Improving the Effectiveness of Juvenile Justice Programs“ spiegelt die Ergebnisse von Metaanalysen zur Wirksamkeit von Programmen zur Rehabilitation jugendlicher Straftäter wider (2010, p.12). Dementsprechend:",
    "es": "El estudio de Lipsey et al. “Improving the Effectiveness of Juvenile Justice Programs” refleja los resultados de los metaanálisis sobre la efectividad de los programas para rehabilitar a los delincuentes juveniles (2010, p.12). En consecuencia:"
  },
  "Pentru infractorii juvenili în general, aparatul de supraveghere al justiției juvenile de probațiune și monitorizare judiciară, casele de grup, facilitățile corecționale și altele asemenea au, în cel mai bun caz, doar efecte favorabile modeste asupra recidivei ulterioare și unele dovezi arată efecte negative modeste (Lipsey și Cullen, 2007; Petrosino, Turpin-Petrosino, și Guckenburg, 2010).": {
    "en": "For juvenile offenders in general, the juvenile justice supervisory apparatus of probation and court monitoring, group homes, correctional facilities, and the like has, at best, only modest favourable effects on subsequent recidivism and some evidence shows modest negative effects (Lipsey and Cullen, 2007; Petrosino, Turpin-Petrosino, and Guckenburg, 2010).",
    "fr": "Pour les délinquants juvéniles en général, l'appareil de surveillance de la justice juvénile de probation et de surveillance judiciaire, les foyers de groupe, les établissements correctionnels, et autres, ont, au mieux, des effets favorables modestes sur la récidive ultérieure et certaines preuves montrent des effets négatifs modestes (Lipsey et Cullen, 2007; Petrosino, Turpin-Petrosino, et Guckenburg, 2010).",
    "it": "Per i delinquenti minorili in generale, l'apparato di sorveglianza della giustizia minorile di libertà vigilata e monitoraggio giudiziario, case famiglia, strutture correzionali e simili ha, nel migliore dei casi, solo modesti effetti favorevoli sulla recidiva successiva e alcune prove mostrano modesti effetti negativi (Lipsey e Cullen, 2007; Petrosino, Turpin-Petrosino, e Guckenburg, 2010).",
    "pt": "Para os delinquentes juvenis em geral, o aparato de supervisão da justiça juvenil de liberdade condicional e monitoramento judicial, lares coletivos, instalações correcionais e similares tem, na melhor das hipóteses, apenas efeitos favoráveis modestos na reincidência subsequente e algumas evidências mostram efeitos negativos modestos (Lipsey e Cullen, 2007; Petrosino, Turpin-Petrosino, e Guckenburg, 2010).",
    "el": "Για τους νεαρούς παραβάτες γενικά, ο εποπτικός μηχανισμός της νεανικής δικαιοσύνης της επιτήρησης και της δικαστικής παρακολούθησης, των ομαδικών σπιτιών, των σωφρονιστικών εγκαταστάσεων και των παρόμοιων έχει, στην καλύτερη περίπτωση, μόνο μέτρια ευνοϊκά αποτελέσματα στην επακόλουθη υποτροπή και ορισμένα στοιχεία δείχνουν μέτρια αρνητικά αποτελέσματα (Lipsey και Cullen, 2007; Petrosino, Turpin-Petrosino, και Guckenburg, 2010).",
    "nl": "Voor jeugdige delinquenten in het algemeen heeft het toezichthoudende apparaat van de jeugdrechtspleging van reclassering en gerechtelijk toezicht, groepswoningen, correctionele inrichtingen en dergelijke, in het beste geval slechts bescheiden gunstige effecten op de daaropvolgende recidive en sommige bewijzen tonen bescheiden negatieve effecten (Lipsey en Cullen, 2007; Petrosino, Turpin-Petrosino, en Guckenburg, 2010).",
    "de": "Für jugendliche Straftäter im Allgemeinen hat der Aufsichtsapparat der Jugendjustiz mit Bewährungs- und Gerichtsüberwachung, Gruppenheimen, Korrektureinrichtungen und dergleichen bestenfalls nur bescheidene günstige Auswirkungen auf die spätere Rückfälligkeit, und einige Beweise zeigen bescheidene negative Auswirkungen (Lipsey und Cullen, 2007; Petrosino, Turpin-Petrosino, und Guckenburg, 2010).",
    "es": "Para los delincuentes juveniles en general, el aparato de supervisión de la justicia juvenil de libertad condicional y supervisión judicial, hogares grupales, centros correccionales y similares, tiene, en el mejor de los casos, solo modestos efectos favorables sobre la reincidencia posterior y algunas pruebas muestran efectos negativos modestos (Lipsey y Cullen, 2007; Petrosino, Turpin-Petrosino y Guckenburg, 2010)."
  },
  "Programmele orientate spre descurajare care se concentrează pe disciplină, supraveghere sau amenințarea cu consecințe punitive (de exemplu, programele de vizitare a închisorii de tip Scared Straight, taberele de antrenament și supravegherea intensivă a libertății condiționate) în medie nu au niciun efect asupra recidivei și pot să o crească (Lipsey, 2009).": {
    "en": "Deterrence-oriented programs that focus on discipline, surveillance, or threat of punitive consequences (e.g. prison visitation Scared Straight–type programs, boot camps, and intensive probation supervision) on average have no effect on recidivism and may increase it (Lipsey, 2009).",
    "fr": "Les programmes orientés vers la dissuasion qui se concentrent sur la discipline, la surveillance ou la menace de conséquences punitives (par exemple, les programmes de type Scared Straight de visite en prison, les camps d'entraînement et la supervision intensive de la probation) n'ont en moyenne aucun effet sur la récidive et peuvent l'augmenter (Lipsey, 2009).",
    "it": "I programmi orientati alla dissuasione che si concentrano sulla disciplina, la sorveglianza o la minaccia di conseguenze punitive (ad esempio, i programmi di tipo Scared Straight di visita in prigione, i campi di addestramento e la supervisione intensiva della libertà vigilata) in media non hanno effetto sulla recidiva e possono aumentarla (Lipsey, 2009).",
    "pt": "Programas orientados para a dissuasão que se concentram na disciplina, vigilância ou ameaça de consequências punitivas (por exemplo, programas de visita à prisão do tipo Scared Straight, campos de treinamento e supervisão intensiva da liberdade condicional) em média não têm efeito na reincidência e podem aumentá-la (Lipsey, 2009).",
    "el": "Τα προγράμματα που εστιάζουν στην αποτροπή που εστιάζουν στην πειθαρχία, την επιτήρηση ή την απειλή των τιμωρητικών συνεπειών (π.χ. προγράμματα τύπου Scared Straight με επίσκεψη στη φυλακή, στρατόπεδα εκπαίδευσης και εντατική εποπτεία της εποπτείας) κατά μέσο όρο δεν έχουν κανένα αποτέλεσμα στην υποτροπή και μπορεί να την αυξήσουν (Lipsey, 2009).",
    "nl": "Afschrikkinggerichte programma's die zich richten op discipline, toezicht of dreiging van straffende gevolgen (bijv. gevangenisbezoekprogramma's van het type Scared Straight, bootcamps en intensieve proeftijdtoezicht) hebben gemiddeld geen effect op recidive en kunnen deze verhogen (Lipsey, 2009).",
    "de": "Abschreckungsorientierte Programme, die sich auf Disziplin, Überwachung oder die Androhung von Strafmaßnahmen konzentrieren (z. B. Besuche im Gefängnis nach dem Scared Straight-Modell, Boot Camps und intensive Bewährungsaufsicht), haben im Durchschnitt keinen Einfluss auf die Rückfallquote und können diese sogar erhöhen (Lipsey, 2009).",
    "es": "Los programas orientados a la disuasión que se centran en la disciplina, la vigilancia o la amenaza de consecuencias punitivas (por ejemplo, programas de visita a la prisión tipo Scared Straight, campamentos militares y supervisión intensiva de la libertad condicional) en promedio no tienen ningún efecto en la reincidencia y pueden aumentarla (Lipsey, 2009)."
  },
  "Multe programe „terapeutice” orientate spre facilitarea schimbării constructive a comportamentului au arătat efecte foarte pozitive, chiar și pentru infractorii serioși (Lipsey, 2009; Lipsey și Cullen, 2007; Lipsey și Wilson, 1998).": {
    "en": "Many “therapeutic” programs oriented toward facilitating constructive behavior change have shown very positive effects—even for serious offenders (Lipsey, 2009; Lipsey and Cullen, 2007; Lipsey and Wilson, 1998).”",
    "fr": "De nombreux programmes « thérapeutiques » orientés vers la facilitation du changement de comportement constructif ont montré des effets très positifs, même pour les délinquants graves (Lipsey, 2009; Lipsey et Cullen, 2007; Lipsey et Wilson, 1998).",
    "it": "Molti programmi « terapeutici » orientati a facilitare un cambiamento comportamentale costruttivo hanno mostrato effetti molto positivi, anche per gravi delinquenti (Lipsey, 2009; Lipsey e Cullen, 2007; Lipsey e Wilson, 1998).",
    "pt": "Muitos programas “terapêuticos” orientados para facilitar a mudança de comportamento construtivo têm mostrado efeitos muito positivos, mesmo para infratores graves (Lipsey, 2009; Lipsey e Cullen, 2007; Lipsey e Wilson, 1998).",
    "el": "Πολλά «θεραπευτικά» προγράμματα που εστιάζουν στη διευκόλυνση της εποικοδομητικής αλλαγής συμπεριφοράς έχουν εμφανίσει πολύ θετικά αποτελέσματα, ακόμη και για σοβαρούς παραβάτες (Lipsey, 2009; Lipsey και Cullen, 2007; Lipsey και Wilson, 1998).",
    "nl": "Veel ‘therapeutische’ programma's gericht op het faciliteren van constructieve gedragsverandering hebben zeer positieve effecten laten zien, zelfs voor ernstige overtreders (Lipsey, 2009; Lipsey en Cullen, 2007; Lipsey en Wilson, 1998).",
    "de": "Viele „therapeutische“ Programme, die darauf abzielen, konstruktive Verhaltensänderungen zu erleichtern, haben sehr positive Effekte gezeigt – sogar bei schweren Straftätern (Lipsey, 2009; Lipsey und Cullen, 2007; Lipsey und Wilson, 1998).",
    "es": "Muchos programas “terapéuticos” orientados a facilitar el cambio constructivo de comportamiento han mostrado efectos muy positivos, incluso para delincuentes graves (Lipsey, 2009; Lipsey y Cullen, 2007; Lipsey y Wilson, 1998)."
  },
  "În același timp, meta-analiza cuprinzătoare a lui Lipsey privind efectele intervențiilor în domeniul delincvenței a creat o bază de date cuprinzătoare care includea 548 de studii efectuate între 1958 și 2002. După cum puteți vedea mai jos în Figura 1, abordările punitive au de fapt un impact negativ asupra recidivei grupurilor țintă, în timp ce abordările terapeutice pot fi clar observate că contribuie consecvent la recidivă și dezvoltare pozitivă (Lipsey, 2009, p.24).": {
    "en": "Along the same lines, Lipsey’s comprehensive meta-analysis on the effects of delinquency interventions created a comprehensive database that included 548 studies conducted between 1958 through 2002. As you can see below in Figure 1, punitive approaches actually have even negative impact on recidivism of the target groups whereas therapeutic approaches can clearly be observed to contribute consequentially to recidivism and positive development (Lipsey, 2009, p.24).",
    "fr": "Dans le même ordre d'idées, la méta-analyse complète de Lipsey sur les effets des interventions en matière de délinquance a créé une base de données complète qui comprenait 548 études menées entre 1958 et 2002. Comme vous pouvez le voir ci-dessous dans la Figure 1, les approches punitives ont en fait un impact négatif sur la récidive des groupes cibles, tandis que les approches thérapeutiques peuvent clairement être observées pour contribuer conséquemment à la récidive et au développement positif (Lipsey, 2009, p.24).",
    "it": "Sulla stessa linea, la meta-analisi completa di Lipsey sugli effetti delle interventi sulla delinquenza ha creato un database completo che includeva 548 studi condotti tra il 1958 e il 2002. Come si può vedere di seguito nella Figura 1, gli approcci punitivi hanno effettivamente un impatto negativo sulla recidiva dei gruppi target, mentre gli approcci terapeutici possono chiaramente essere osservati per contribuire conseguentemente alla recidiva e allo sviluppo positivo (Lipsey, 2009, p.24).",
    "pt": "No mesmo sentido, a meta-análise abrangente de Lipsey sobre os efeitos das intervenções na delinquência criou uma base de dados abrangente que incluiu 548 estudos realizados entre 1958 e 2002. Como você pode ver abaixo na Figura 1, as abordagens punitivas têm, na verdade, um impacto negativo na reincidência dos grupos-alvo, enquanto as abordagens terapêuticas podem ser claramente observadas para contribuir consequentemente para a reincidência e desenvolvimento positivo (Lipsey, 2009, p.24).",
    "el": "Παράλληλα, η περιεκτική μετα-ανάλυση του Lipsey για τα αποτελέσματα των παρεμβάσεων στην παραβατικότητα δημιούργησε μια περιεκτική βάση δεδομένων που περιελάμβανε 548 μελέτες που διεξήχθησαν μεταξύ του 1958 και του 2002. Όπως μπορείτε να δείτε παρακάτω στο Σχήμα 1, οι τιμωρητικές προσεγγίσεις έχουν πράγματι αρνητική επίδραση στην υποτροπή των στόχων, ενώ οι θεραπευτικές προσεγγίσεις μπορούν σαφώς να παρατηρηθούν να συμβάλλουν συνεπώς στην υποτροπή και τη θετική ανάπτυξη (Lipsey, 2009, σελ.24).",
    "nl": "In dezelfde lijn heeft Lipsey's uitgebreide meta-analyse over de effecten van delinquentie-interventies een uitgebreide database gecreëerd die 548 studies omvatte die tussen 1958 en 2002 zijn uitgevoerd. Zoals u hieronder in Figuur 1 kunt zien, hebben straffende benaderingen eigenlijk een negatieve invloed op recidive van de doelgroepen, terwijl therapeutische benaderingen duidelijk kunnen worden waargenomen om consequent bij te dragen aan recidive en positieve ontwikkeling (Lipsey, 2009, p.24).",
    "de": "In ähnlicher Weise erstellte Lipseys umfassende Metaanalyse zu den Auswirkungen von Interventionsmaßnahmen bei Jugendkriminalität eine umfassende Datenbank, die 548 Studien aus den Jahren 1958 bis 2002 enthielt. Wie Sie in Abbildung 1 unten sehen können, haben strafende Ansätze tatsächlich negative Auswirkungen auf die Rückfallquote der Zielgruppen, während therapeutische Ansätze klar erkennbar sind und konsequent zur Rückfallquote und positiven Entwicklung beitragen (Lipsey, 2009, p.24).",
    "es": "En la misma línea, el metaanálisis integral de Lipsey sobre los efectos de las intervenciones en la delincuencia creó una base de datos integral que incluyó 548 estudios realizados entre 1958 y 2002. Como puede ver a continuación en la Figura 1, los enfoques punitivos en realidad tienen un impacto negativo en la reincidencia de los grupos objetivo, mientras que los enfoques terapéuticos pueden observarse claramente como contribuyentes a la reincidencia y el desarrollo positivo (Lipsey, 2009, p.24)."
  },
  "Figura 1. Efectul mediu al recidivei pentru categoriile de programe": {
    "en": "Figure 1. Mean recidivism effect for the program categories",
    "fr": "Figure 1. Effet moyen de la récidive pour les catégories de programmes",
    "it": "Figura 1. Effetto medio della recidiva per le categorie di programmi",
    "pt": "Figura 1. Efeito médio de reincidência para as categorias de programas",
    "el": "Σχήμα 1. Μέσο αποτέλεσμα υποτροπής για τις κατηγορίες προγραμμάτων",
    "nl": "Figuur 1. Gemiddeld recidive-effect voor de programmacategorieën",
    "de": "Abbildung 1. Durchschnittlicher Rückfalleffekt für die Programmkategorien",
    "es": "Figura 1. Efecto medio de la reincidencia para las categorías de programas"
  },
  "Consecințele abordărilor terapeutice fiind spuse, categoria „Consiliere” poate fi considerată cea mai eficientă printre celelalte. O analiză mai profundă în cadrul acestei categorii arată o diferențiere chiar mai mare între diferitele metodologii care aduc schimbări în viața tinerilor. Printre diferitele tipuri de consiliere, așa cum se poate vedea în Figura 2 de mai jos, „consilierea de grup” și „mentoratul” sunt cele două metodologii cele mai promițătoare (Lipsey, 2009, p.25).": {
    "en": "The consequentiality of therapeutic approaches being said, “Counseling” category can be said to be the most effective among others. A deeper study within this category shows even greater differentiation among different methodologies bringing change in the youth’s life. Among different kinds of counselling, as can be seen in Figure 2 below, “group counselling” and “mentoring” are the two most promising methodologies (Lipsey, 2009, p.25).",
    "fr": "Cela étant dit, la conséquentialité des approches thérapeutiques, la catégorie « Conseil » peut être considérée comme la plus efficace parmi les autres. Une étude plus approfondie de cette catégorie montre une différenciation encore plus grande entre les différentes méthodologies apportant un changement dans la vie des jeunes. Parmi les différents types de conseils, comme on peut le voir dans la Figure 2 ci-dessous, le « conseil de groupe » et le « mentorat » sont les deux méthodologies les plus prometteuses (Lipsey, 2009, p.25).",
    "it": "Detto ciò, la consequentialità degli approcci terapeutici, la categoria « Consulenza » può essere considerata la più efficace tra le altre. Uno studio più approfondito all'interno di questa categoria mostra una maggiore differenziazione tra le diverse metodologie che portano al cambiamento nella vita dei giovani. Tra i diversi tipi di consulenza, come si può vedere nella Figura 2 qui sotto, il «consulenza di gruppo» e il «mentoring» sono le due metodologie più promettenti (Lipsey, 2009, p.25).",
    "pt": "Dito isso, a consequencialidade das abordagens terapêuticas, a categoria “Aconselhamento” pode ser considerada a mais eficaz entre as outras. Um estudo mais aprofundado dentro desta categoria mostra uma diferenciação ainda maior entre diferentes metodologias que trazem mudanças na vida dos jovens. Entre os diferentes tipos de aconselhamento, como pode ser visto na Figura 2 abaixo, “aconselhamento em grupo” e “mentoria” são as duas metodologias mais promissoras (Lipsey, 2009, p.25).",
    "el": "Μετά την αναφορά της συνεπειότητας των θεραπευτικών προσεγγίσεων, η κατηγορία «Συμβουλές» μπορεί να θεωρηθεί η πιο αποτελεσματική μεταξύ των άλλων. Μια βαθύτερη μελέτη εντός αυτής της κατηγορίας δείχνει ακόμη μεγαλύτερη διαφοροποίηση μεταξύ διαφορετικών μεθοδολογιών που φέρνουν αλλαγή στη ζωή των νέων. Ανάμεσα σε διαφορετικούς τύπους συμβουλών, όπως φαίνεται στο Σχήμα 2 παρακάτω, το «ομαδικό coaching» και η «σύμβουλευτική» είναι οι δύο πιο ελπιδοφόρες μεθοδολογίες (Lipsey, 2009, σελ.25).",
    "nl": "De consequentie van therapeutische benaderingen gezegd hebbende, kan worden gezegd dat de categorie ‘Counseling’ de meest effectieve is onder de anderen. Een diepgaander onderzoek binnen deze categorie toont zelfs een grotere differentiatie tussen verschillende methodologieën die verandering in het leven van de jongeren brengen. Onder verschillende soorten counseling, zoals te zien is in Figuur 2 hieronder, zijn “groepscounseling” en “mentoring” de twee meest veelbelovende methodologieën (Lipsey, 2009, p.25).",
    "de": "Die Wirksamkeit therapeutischer Ansätze berücksichtigt, kann gesagt werden, dass die Kategorie „Beratung“ die effektivste ist. Eine tiefere Analyse innerhalb dieser Kategorie zeigt eine noch größere Differenzierung zwischen verschiedenen Methodiken, die Veränderungen im Leben der Jugendlichen bewirken. Unter den verschiedenen Arten von Beratung sind, wie in Abbildung 2 unten zu sehen ist, „Gruppenberatung“ und „Mentoring“ die beiden vielversprechendsten Methodiken (Lipsey, 2009, p.25).",
    "es": "Dicho lo anterior sobre la importancia de los enfoques terapéuticos, se puede decir que la categoría “Consejería” es la más efectiva entre otras. Un estudio más profundo dentro de esta categoría muestra una diferenciación aún mayor entre las diferentes metodologías que traen cambios en la vida de los jóvenes. Entre los diferentes tipos de asesoramiento, como se puede ver en la Figura 2 a continuación, el “asesoramiento grupal” y la “mentoría” son las dos metodologías más prometedoras (Lipsey, 2009, p.25)."
  },
  "Figura 2. Efectele medii de recidivă pentru diferite metodologii din categoria de consiliere": {
    "en": "Figure 2. Mean recidivism effects for different methodologies within the counseling category",
    "fr": "Figure 2. Effets moyens de la récidive pour différentes méthodologies dans la catégorie des conseils",
    "it": "Figura 2. Effetti medi di recidiva per diverse metodologie all'interno della categoria di consulenza",
    "pt": "Figura 2. Efeitos médios de reincidência para diferentes metodologias dentro da categoria de aconselhamento",
    "el": "Σχήμα 2. Μέσο αποτελέσματα υποτροπής για διαφορετικές μεθοδολογίες εντός της κατηγορίας συμβουλών",
    "nl": "Figuur 2. Gemiddelde recidive-effecten voor verschillende methodologieën binnen de categorie counseling",
    "de": "Abbildung 2. Durchschnittliche Rückfalleffekte für verschiedene Methodiken innerhalb der Kategorie Beratung",
    "es": "Figura 2. Efectos medios de la reincidencia para diferentes metodologías dentro de la categoría de consejería"
  },
  'Vă rugăm să permiteți pop-up-urile pentru a printa': {
    "en": "Please allow pop-ups to print",
    "fr": "Veuillez autoriser les fenêtres contextuelles pour imprimer",
    "it": "Si prega di consentire i popup per stampare",
    "pt": "Por favor, permita pop-ups para imprimir",
    "el": "Παρακαλώ επιτρέψτε τα αναδυόμενα παράθυρα για εκτύπωση",
    "nl": "Schakel pop-ups in om af te drukken",
    "de": "Bitte erlauben Sie Pop-ups zum Drucken",
    "es": "Por favor, permita las ventanas emergentes para imprimir"
  },
};
