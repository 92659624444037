import React from 'react';
import { CountriesSymbol, MenteeResourceLang, MentorResourceLang, useResourceTranslate, useTranslate } from '../../common/lang';
import { useUser } from '../../hooks/useUser';
import { MenteeResourceLink, MentorResourceLink } from '../../common/resources';
import { useLang } from '../../hooks/useLang';
import Iframe from '../Iframe';
import { Link } from 'react-router-dom';
import Lang from '../Lang';
import Button from '../Button';
import Fa from '../FontAwesome';
import { API_URL, IS_ANDROID, IS_IOS } from '../../common/env';

const ResourcesLinks = ({ view, setView }: any) => {
    const translateResource = useResourceTranslate();
    const { user } = useUser();
    const { lang } = useLang();
    const translate = useTranslate();
    const [scrollY, setScrollY] = React.useState(0);
    const resources = Object.keys(user.role === "Mentorat" ? MenteeResourceLang : MentorResourceLang);
    const linkOf = (r: string) => {
        const source = user.role === "Mentorat" ? MenteeResourceLink : MentorResourceLink;
        const base = user.role === "Mentorat" ? 'Mentee' : 'Mentor';
        const country = CountriesSymbol[lang];
        const filename = (source as any)[r]["English"].filename.replace('.pdf', '.html');
        return (`/resources/${base}/${r}/${country}/${filename}`);
    };
    const saveScroll = () => {
        setScrollY(window.scrollY);
        window.scrollTo(0, 0);
    }
    const restoreScroll = () => {
        setTimeout(() => window.scrollTo(0, scrollY), 100);
    }

    function handlePrint() {
        // URL-ul către backend pentru a genera conținutul de printat
        // const printUrl = (window.location.origin + linkOf(view)).replace('3033', '3030');
        const originUrl = window.location.origin.includes('localhost') ? `${window.location.origin}/api` : API_URL;
        const backendUrl = (IS_ANDROID || IS_IOS) ? API_URL : originUrl;
        const printUrl = (`${backendUrl}/print/resource/` + btoa(linkOf(view))).replace('3033', '3030');
        window.open(printUrl, '_blank');
    }

    return (
        <>
            {view ? <>
                <Button onClick={(e) => [setView(null), restoreScroll(), e.preventDefault()]}>
                    <Fa duotone="circle-arrow-left fa-xl" /> <Lang text='Pagina anterioară' />
                </Button>
                <div className="btn print-button">
                    <Button onClick={handlePrint}>
                        <Fa duotone="print fa-xl" /> <Lang text='Printeaza' />
                    </Button>
                </div>
                <Iframe file={linkOf(view)} />
            </>
                : <ul className='nav flex-column'>
                    {resources.map(r => (
                        <li key={r} className='nav-link'>
                            <Link to='' onClick={e => [e.preventDefault(), setView(r), saveScroll()]}>{translateResource(r as any)}</Link>
                        </li>
                    ))}
                </ul>
            }
        </>
    )
}

export default ResourcesLinks;