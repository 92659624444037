import React, { useEffect } from 'react'
import Motto from './Motto'
import DatePicker from 'react-calendar';
import { OnArgs, Value } from 'react-calendar/dist/cjs/shared/types';
import { useQuery } from '@tanstack/react-query';
import { useUser } from '../hooks/useUser';
import sql from '../common/sql';
import { MentoringSessionEntity } from '../../../backend/src/shared/entity';
import CalendarMentee from './Calendar/CalendarMentee';
import CalendarMentor from './Calendar/CalendarMentor';
import { formatLongDate } from '../common/dateFormatter';

type MentoringSession = MentoringSessionEntity & { Coordinador: string, Mentor: string, Mentee: string };
const Calendar = () => {
    const locale = 'en-US';
    const [selectedDate, setSelectedDate] = React.useState<Value>(new Date());
    const [activeStartDate, setActiveStartDate] = React.useState<Date>(new Date());
    const { user } = useUser();
    const sessions = useQuery({
        queryKey: ['mentoringSessionByIdUserQuery', user.account?.Id],
        queryFn: () => sql.mentoringSessionByIdUserQuery({ idUser: user.account?.Id }) as Promise<Array<MentoringSession>>,
        enabled: !!user.account?.Id
    });
    const handleChange = (e: Value) => {
        setSelectedDate(e);
    }
    const monthChanged = (e: Date) => {
        console.log(e);
    }
    const onActiveStartDateChange = (e: OnArgs) => {
        setActiveStartDate(e.activeStartDate || new Date());
    }
    const showSchedule = () => {
        const dates = sessions.data?.map(s => new Date(s.DateProposal)) || [];
        dates.forEach((d) => {
            const day = d.getDate();
            const date = formatLongDate(locale, d);
            const style = [
                'border',
                'border-dark',
                'bg-warning',
                'bg-gradient',
                'rounded-5',
                'p-1'
            ].concat(day > 9 ? ['px-5px'] : ['px-2']);
            document.querySelector(`abbr[aria-label="${date}"]`)?.classList.add(...style);
        }
        );
    }
    useEffect(() => showSchedule());
    useEffect(() => {
        if (selectedDate) {
            // custom style for selectedDate
            const day = (selectedDate as Date).getDate();
            const date = formatLongDate(locale, selectedDate as Date);
            const style = [
                'border',
                'border-dark',
                'bg-info',
                'bg-gradient',
                'rounded-5',
                'p-1'
            ].concat(day > 9 ? ['px-5px'] : ['px-2']);
            document.querySelector(`abbr[aria-label="${date}"]`)?.classList.add(...style);
            return () => {
                document.querySelector(`abbr[aria-label="${date}"]`)?.classList.remove(...style);
            }
        }
    }, [selectedDate]);
    // console.log({ activeStartDate });
    return (
        <div className='row'>
            <Motto>Calendar</Motto>
            <DatePicker
                locale={locale}
                onClickMonth={monthChanged}
                onChange={handleChange}
                value={selectedDate}
                onActiveStartDateChange={onActiveStartDateChange} />
            {
                user.role === 'Mentorat'
                    ? <CalendarMentee selectedDate={selectedDate as Date} sessions={sessions.data || []} />
                    : <CalendarMentor selectedDate={selectedDate as Date} sessions={sessions.data || []} />
            }
        </div>
    )
}

export default Calendar